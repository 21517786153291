import React from "react";
import MapFrame from "../utils/MapFrame";

export default function LiveHelp(props) {
    let [helps, setHelps] = React.useState([]);
    let [selectedHelp, setSelectedHelp] = React.useState(null);
    let acceptHelp = () => {
        props.setShowMainSpinner(true);
        fetch(props.url + 'task/helper-queue/' + selectedHelp.id + '/', {
            method: 'POST',
            headers: props.headers,
            body: JSON.stringify({
                'help_id': selectedHelp.id,
                'proposed_at': document.getElementById(selectedHelp.id + '-proposed-at').value,
                'proposed_amount': document.getElementById(selectedHelp.id + '-proposed-amount').value,
            })
        }).then(res => res.json()).then(res => {
            if (res.data) {
                props.setNotification(res.message);
                document.getElementById('back-btn').click();
            } else {
                props.setNotification(res.message || 'Something went wrong!');
            }
        }).then(() => props.setShowMainSpinner(false))
    }
    const MINUTE_MS = 5000;
    let baseUrl = props.url;

    React.useEffect(() => {
        const interval = setInterval(() => {
            fetch(props.url + 'task/help-requests/', {
                method: 'GET',
                headers: props.headers
            }).then(res => res.json()).then(res => {
                if (res.data) {
                    setHelps(res.data);
                } else {
                    props.setNotification(res.message || 'Something went wrong!');
                }
            })
        }, MINUTE_MS);
        props.setPageTitle('Searching...');
        return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }, []);
    if (selectedHelp) {
        return <React.Fragment>
            <div className="badge bg-primary">
                <small className="fw-semibold">Scheduled at : {selectedHelp.scheduled_at.replace('T', ' ')}</small>
            </div>
            <dl className="row mt-2">
                <dt className="col-sm-3">Service</dt>
                <dd className="col-sm-9">{selectedHelp.service.title}</dd>
                <dt className="col-sm-3">Unit</dt>
                <dd className="col-sm-9">{selectedHelp.unit}</dd>
                <dt className="col-sm-3">Budget amount</dt>
                <dd className="col-sm-9">
                    <dl className="row">
                        <dd className="col-sm-10">
                            <i className="bx bx-rupee">
                                <strong>
                                    {selectedHelp.budget_amount}
                                </strong>
                            </i>
                        </dd>
                    </dl>
                </dd>
                <dt className="col-sm-3">Description</dt>
                <dd className="col-sm-9">
                    <p>{selectedHelp.description}</p>
                </dd>


                <dt className="col-sm-3">Address</dt>
                <dd className="col-sm-9">
                    <dl className="row">
                        <dd className="col-sm-8">
                            <div>
                                <MapFrame latitude={selectedHelp.address.latitude}
                                          longitude={selectedHelp.address.longitude}/>
                            </div>
                            <div className="alert alert-secondary border">
                                {selectedHelp.address.get_name},<br/>
                                {selectedHelp.address.locality}, <br/>
                                {selectedHelp.address.city}, <br/>
                                {selectedHelp.address.postal_code}, <br/>
                                {selectedHelp.address.state}, <br/>
                                {selectedHelp.address.country}
                            </div>
                        </dd>
                    </dl>
                </dd>

            </dl>
            <div className="row form-row">
                <div className="mb-3 input-group">
                    <span className="input-group-text"><i className="bx bx-rupee"></i></span>
                    <input
                        type="number" id={selectedHelp.id + "-proposed-amount"}
                        className="form-control"
                        placeholder="Different amount?"/>
                    <span className="input-group-text" aria-disabled={true}>.00</span>
                </div>
                <div className="form-floating mb-3">
                    <input type="datetime-local" className="form-control" id={selectedHelp.id + "-proposed-at"}
                           placeholder="Do it at a different time?(optional)"/>
                    <label htmlFor="floatingInput">Do it at a different time?(optional)</label>
                </div>
            </div>
            <button type="button" style={{'width': '100%'}} className="btn btn-success" onClick={() => acceptHelp()}>
                Accept
            </button>
            <br/>
            <br/>
            <div style={{'marginLeft': '12rem'}}>
                <a href="#" id="back-btn" className="btn btn-success btn-buy-now" onClick={() => setSelectedHelp(null)}
                   style={{'marginBottom': '2rem'}}>
                    <i className="bx bx-arrow-back"></i>
                    Back
                </a></div>
        </React.Fragment>
    }
    return <React.Fragment>

        <div className="col-md-6 col-lg-4 order-2 mb-4">
            <div className="card h-100">
                <div className="card-header d-flex align-items-center justify-content-between">
                    <h5 className="card-title m-0 me-2">Live Help Requests</h5>
                    <div className="dropdown">
                        {/* <button
                            className="btn p-0"
                            type="button"
                            id="transactionID"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false">
                            <i className="bx bx-dots-vertical-rounded"></i>
                        </button>
                        <div className="dropdown-menu dropdown-menu-end" aria-labelledby="transactionID">
                            <a className="dropdown-item" href="#">Last 28 Days</a>
                            <a className="dropdown-item" href="#">Last Month</a>
                            <a className="dropdown-item" href="#">Last Year</a>
                        </div> */}
                    </div>
                </div>
                <div className="card-body">
                    <ul className="p-0 m-0">
                        {helps && helps.map(help => {
                            return <li key={help.id} className="d-flex mb-4 pb-1" onClick={() => setSelectedHelp(help)}>
                                <div
                                    className="d-flex w-100 alert alert-light border flex-wrap align-items-center justify-content-between gap-2">
                                    <div className="me-2">
                                        <small className="text-muted d-block mb-1">When
                                            - {help.scheduled_at ? help.scheduled_at.replace('T', ' ') : "Any time"}</small>
                                        <h6 className="mb-0">{help.service}</h6>
                                    </div>
                                    <div className="user-progress d-flex align-items-center gap-1">
                                        <i className="bx bx-rupee"></i>{help.budget_amount}
                                    </div>
                                </div>
                            </li>;
                        })}
                    </ul>
                </div>
            </div>
        </div>


        <div className="queue-loader">
            <span></span>
            <span></span>
            <span></span>
        </div>


    </React.Fragment>
}