import React from "react";
import HelpImages from "../utils/HelpImages";
import MapFrame from "../utils/MapFrame";
import Spinner from "../utils/Spinner";
import Stars from "../utils/Stars";
import Statuses from "../utils/Statuses";
import Helper from "../utils/Helper";

export default function Track(props) {
    let obj = props.help;
    const statusClassDict = {
        1: 'alert-success',
        2: 'alert-primary',
        3: 'alert-secondary',
        4: 'alert-danger',

    }

    let baseUrl = props.url;
    const MINUTE_MS = 15000;

    function cancelHelp() {
        let r = window.confirm('Are you sure?');
        if (r) {
            fetch(props.url + 'task/cancel/' + obj.id + '/', {
                method: 'POST',
                headers: props.headers,
                body: JSON.stringify({
                    'otp': document.getElementById('otp').value,
                })
            }).then(res => res.json()).then(res => {
                if (res.data) {
                    props.setNotification(res.message);
                    setTimeout(function () {
                        window.location.href = '/help';
                    }, 2000);
                } else {
                    props.setNotification(res.message || 'Something went wrong!');
                }
            })
        }
    }

    function addReview() {
        props.setShowMainSpinner(true);
        fetch(props.url + 'task/review/' + obj.helper_id + '/', {
            method: 'POST',
            headers: props.headers,
            body: JSON.stringify({
                'stars': document.getElementById('rating').value,
                'comment': document.getElementById('review-comment').value,
            })
        }).then(res => res.json()).then(res => {
            if (res.data) {
                props.setNotification(res.message);
            } else {
                props.setNotification(res.message || 'Something went wrong!');
            }
        }).then(() => {
            props.setShowMainSpinner(false)
            document.getElementById('close-review-modal-btn').click();
        })
    }
    React.useEffect(() => {
        props.fetchHelp(obj.id);
        const interval = setInterval(() => {
            props.fetchHelp(obj.id);
        }, MINUTE_MS);
        props.setPageTitle('Track');
        return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }, []);


    return <React.Fragment>
        {!obj ?
            <Spinner />
            :
            <section>
                {!obj.latitude && <div className="alert alert-danger border">Location not available</div>}
                <br />
                <div className={"alert border " + statusClassDict[obj.status_val]}>
                    {obj.status_val === 2 && <div className="progress">
                        <div
                            className="progress-bar progress-bar-striped progress-bar-animated bg-success"
                            role="progressbar"
                            style={{ "width": "55%" }}
                            aria-valuenow="55"
                            aria-valuemin="0"
                            aria-valuemax="100"
                        ></div>
                    </div>}
                    {obj.status}
                </div>
                <div className="d-flex align-items-center justify-content-center">
                    <button type="button"
                        className="btn btn-primary"
                        data-bs-toggle="modal"
                        data-bs-target="#objModal">
                        Help details
                    </button>
                    <span style={{ 'width': '3rem' }}></span>
                    {obj.status_val === 2 ? <a href={'/payment/' + obj.id} className="btn btn-success shadow-lg">Pay now</a> : <button type="button" className="btn btn-danger shadow-lg" disabled>Cancelled</button>}
                </div>


                <div className="row mb-5">
                    {obj.latitude && <div className="col-md-6 col-lg-4">
                        <h6 className="mt-2 text-muted">Helper location</h6>
                        <MapFrame latitude={obj.latitude} longitude={obj.longitude} />
                    </div>}
                    <div className="d-flex align-items-center justify-content-center">
                        <br />
                        <br />
                        {obj.status_val !== 4 && obj.status_val > 1 && <React.Fragment>
                            <button type="button"
                                className="btn btn-primary btn-sm"
                                style={{ 'width': '49%' }}
                                data-bs-toggle="modal"
                                data-bs-target="#helperModal">
                                Helper details
                            </button>
                            &nbsp;
                            <button type="button"
                                className="btn btn-primary btn-sm"
                                style={{ 'width': '49%' }}
                                data-bs-toggle="modal"
                                data-bs-target="#reviewModal">
                                Add a review
                            </button></React.Fragment>}

                    </div>
                    <div className="col-md-6 col-lg-4">
                        <Statuses baseUrl={baseUrl} statuses={props.statuses} />
                    </div>
                </div>


                {obj.status_val === 1 && <a href='#' data-bs-toggle="modal"
                    data-bs-target="#cancelModal" style={{ 'width': '100%' }} className="btn btn-sm btn-outline-danger">Withdraw request?</a>}
                <br />
                <br />
                <br />
                <br />
                <div style={{ 'marginRight': '2rem' }}> {obj.status_val < 3 &&
                    <a href={"tel:" + obj.contact_no} className="btn btn-success btn-buy-now">
                        <i className="bx bx-phone"></i>
                        Call helper
                    </a>
                }&nbsp;&nbsp;
                    <a href="#" className="btn btn-success btn-buy-now" onClick={() => { props.helpClicked(null); }}>
                        <i className="bx bx-arrow-back"></i>
                        Back
                    </a> </div>

                <div className="modal fade" id="objModal" data-bs-backdrop="/static" tabIndex="-1">
                    <div className="modal-dialog">
                        <form className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="backDropModalTitle">Help summary</h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                ></button>
                            </div>
                            <div className="modal-body">
                                <div className="card-body">
                                    <small className="text-light fw-semibold">{obj.scheduled_at.replace('T', ' ')}</small>
                                    <dl className="row mt-2">
                                        <dt className="col-sm-3">Service</dt>
                                        <dd className="col-sm-9">{obj.service}</dd>
                                        <dt className="col-sm-3">Unit</dt>
                                        <dd className="col-sm-9">{obj.unit}</dd>

                                        <dt className="col-sm-3">Description</dt>
                                        <dd className="col-sm-9">
                                            <p>{obj.description}</p>
                                        </dd>
                                        <HelpImages baseUrl={baseUrl} images={props.images} />

                                        <dt className="col-sm-3">Address</dt>
                                        <dd className="col-sm-9">
                                            <dl className="row">
                                                <dd className="col-sm-8">
                                                    <div className="alert alert-secondary border">
                                                        {obj.address.get_name},<br />
                                                        {obj.address.locality}, <br />
                                                        {obj.address.city}, <br />
                                                        {obj.address.postal_code}, <br />
                                                        {obj.address.state}, <br />
                                                        {obj.address.country}
                                                    </div>
                                                </dd>
                                            </dl>
                                        </dd>
                                        <dt className="col-sm-3">Payment</dt>
                                        <dd className="col-sm-9">
                                            <dl className="row">
                                                <dd className="col-sm-10">
                                                    <div className="table-bordered table table-responsive">
                                                        <table className="table">
                                                            <tbody>
                                                                <tr>
                                                                    <th>Budget amount</th>
                                                                    <th>
                                                                        <i className="bx bx-rupee">{obj.budget_amount}</i>
                                                                    </th>
                                                                </tr>
                                                                <tr>
                                                                    <th>Sub total</th>
                                                                    <th>
                                                                        <i className="bx bx-rupee">{obj.sub_total_amount}</i>
                                                                    </th>
                                                                </tr>
                                                                <tr>
                                                                    <th>Tax (GST)</th>
                                                                    <th>
                                                                        <i className="bx bx-rupee">{obj.tax_amount}</i>
                                                                    </th>
                                                                </tr>
                                                                <tr><th><strong>Total</strong></th>
                                                                    <th>
                                                                        <i className="bx bx-rupee">{obj.total_amount}</i>
                                                                    </th>
                                                                </tr>
                                                            </tbody>
                                                        </table>

                                                    </div>
                                                </dd>
                                            </dl>
                                        </dd>
                                    </dl>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" style={{ 'width': '100%' }} className="btn btn-outline-secondary" data-bs-dismiss="modal">
                                    Close
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="modal fade" id="cancelModal" data-bs-backdrop="/static" tabIndex="-1">
                    <div className="modal-dialog">
                        <form className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="backDropModalTitle">Are you sure?</h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                ></button>
                            </div>
                            <div className="modal-body">
                                <div className="card-body">
                                    <small className="text-light fw-semibold">Cancel help request</small>
                                    <dl className="row mt-2">
                                        <dt className="col-sm-3">Enter OTP given by helper</dt>
                                        <dd className="col-sm-9"><input type={"number"} className="form-control" id="otp" /></dd>
                                    </dl>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button onClick={() => { cancelHelp() }} type="button" style={{ 'width': '100%' }} className="btn btn-outline-danger" data-bs-dismiss="modal">
                                    Cancel
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="modal fade" id="reviewModal" data-bs-backdrop="/static" tabIndex="-1">
                    <div className="modal-dialog">
                        <form className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="backDropModalTitle">Add a review</h5>
                                <button
                                    id="close-review-modal-btn"
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                ></button>
                            </div>
                            <div className="modal-body">
                                <div className="card-body">
                                    <small className="text-light fw-semibold">Share your opinion on the helper.</small>
                                    <dl className="row mt-2">
                                        <dt className="col-sm-3"><Stars />

                                        </dt>
                                        <dt>
                                            &nbsp;
                                        </dt>
                                        <dd className="col-sm-9">
                                            <textarea className="form-control" id="review-comment" placeholder="Type here...">

                                            </textarea>
                                        </dd>
                                    </dl>
                                </div>
                                <div className="modal-footer">
                                    <button onClick={() => { addReview() }} type="button" style={{ 'width': '100%' }} className="btn btn-primary">
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="modal fade" id="helperModal" data-bs-backdrop="/static" tabIndex="-1">
                    <div className="modal-dialog">
                        <form className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="helperModalTitle"></h5>
                                <button
                                    id="close-helper-modal"
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                ></button>
                            </div>
                            <div className="modal-body">
                                {props.helper && <Helper helper={props.helper} baseUrl={baseUrl} />}
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        }
    </React.Fragment>
}