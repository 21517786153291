import React from "react";

export default function Support(props) {
    React.useEffect(() => {
        props.setPageTitle('Support');
    }, []);

    function submitForm(event) {
        event.preventDefault();
        document.getElementById('support-loader').style.display = '';

        fetch(props.url + 'support/', {
            method: 'POST',
            headers: props.headers,
            body: JSON.stringify({
                'message': document.getElementById('message').value,
            })
        }).then(res => res.json()).then(res => {
            if (res.data) {
                document.getElementById('support-loader').style.display = 'none';
                props.setNotification(res.message);
                setTimeout(function () {
                    window.location.reload();
                }, 2000);
            } else {
                props.customAlert(res.message || 'Something went wrong!');
            }
        })
    }

    return <React.Fragment>


        <form  onSubmit={(event) => submitForm(event)}>
            <div className="card-body" id="support-form">
                <div className="card-header">
                    <div className="alert alert-success">
                        We usually respond back in an hour!
                    </div>
                </div>

                <div>
                    <textarea className={"form-control"} id={"message"} name={"message"} placeholder={"Your message..."} rows="9" cols="50">

                    </textarea>
                </div>


                <center>
                    <div style={{'display': 'none'}} id="support-loader"
                         className="spinner-border" role="status">
                    </div>
                    <br/>
                    <button type="submit" className="btn btn-md btn-primary">
                        <span><i className="bx bx-support"></i> &nbsp;&nbsp;&nbsp;Submit</span>
                    </button>
                </center>
            </div>
        </form>

    </React.Fragment>
}