import React, {useContext} from "react";
import Track from "./Track";
import HelperTrack from "./HelperTrack";
import Queue from "./Queue";
import Helps from "../utils/Helps";
import {MainContext} from '../Frame';

export default function History(props) {
    const {setShowMainSpinner} = React.useContext(MainContext);
    let [liveHelps, setLiveHelps] = React.useState([]);
    let [cancelledHelps, setCancelledHelps] = React.useState([]);
    let [completedHelps, setCompletedHelps] = React.useState([]);
    let [help, setHelp] = React.useState(null);
    let [helper, setHelper] = React.useState(null);
    let [images, setImages] = React.useState([]);
    let [statuses, setStatuses] = React.useState([]);

    function fetchHelp(helpId) {
        setShowMainSpinner(true);
        fetch(props.url + 'task/track/' + helpId, {
            method: 'GET',
            headers: props.headers
        }).then(res => res.json()).then(res => {
            if (res.data) {
                setHelp(res.data.help);
                setHelper(res.data.helper);
                setStatuses(res.data.statuses);
                setImages(res.data.images);

            } else {
                props.setNotification(res.message || 'Something went wrong!');
            }
        }).then(() => setShowMainSpinner(false))
    }

    function helpClicked(help) {
        if (!help) {
            setHelp(null);
            setStatuses([]);
        } else {
            fetchHelp(help.id);
        }
    }

    function refreshHelpList() {
        setLiveHelps([])
        setCancelledHelps([])
        setCompletedHelps([])
        fetch(props.url + 'user/my-task/', {
            method: 'GET',
            headers: props.headers,
        }).then(res => res.json()).then(res => {
            if (res.data) {
                res.data.forEach(element => {
                    if (element.is_active) {
                        liveHelps.push(element);
                        setLiveHelps(liveHelps);
                    } else if (element.status_val === 3) {
                        completedHelps.push(element);
                        setCompletedHelps(completedHelps);
                    } else if (element.status_val === 4) {
                        cancelledHelps.push(element);
                        setCancelledHelps(cancelledHelps);
                    }
                });
            } else if (res.error) {
                props.setNotification(res.message || 'Something went wrong!');
            }
        }).then(() => setShowMainSpinner(false))
    }

    React.useEffect(() => {
        setShowMainSpinner(true);
        // if (liveHelps.length === 0) {
        //     refreshHelpList();
        // }
        props.setPageTitle(props.helperMode ? "My helps" : "Helps assigned to me")
    }, []);
    if (help) {
        if (props.helperMode) {
            return <HelperTrack url={props.url} setShowMainSpinner={setShowMainSpinner} images={images}
                                statuses={statuses} helpClicked={helpClicked} setPageTitle={props.setPageTitle}
                                cookies={props.cookies} customAlert={props.customAlert}
                                headers={props.headers} help={help} fetchHelp={fetchHelp}
                                setNotification={props.setNotification}/>
        } else {
            if (help.status_val === 0) {
                return <Queue setNotification={props.setNotification} setShowMainSpinner={setShowMainSpinner}
                              setPageTitle={props.setPageTitle} url={props.url} cookies={props.cookies}
                              customAlert={props.customAlert}
                              headers={props.headers} help={help}/>
            } else {
                return <Track setNotification={props.setNotification} setShowMainSpinner={setShowMainSpinner}
                              url={props.url} images={images} statuses={statuses} helpClicked={helpClicked}
                              setPageTitle={props.setPageTitle} cookies={props.cookies} customAlert={props.customAlert}
                              headers={props.headers} help={help} fetchHelp={fetchHelp} helper={helper}/>
            }
        }
    }

    return <React.Fragment>
        <div className="container">
            <div className="row">
                <div className="card">
                    <div className="card-header">
                        <h6 className="text-muted">{props.isHelperMode === false ? "My helps" : "Helps assigned to me"}</h6>
                    </div>
                    <div className="card-body">
                        <ul className="nav nav-tabs flex-sm-row" id="myTabs" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button
                                    className="nav-link active"
                                    id="live-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#navs-top-live"
                                    type="button"
                                    role="tab"
                                    aria-controls="navs-top-live"
                                    aria-selected="true"
                                >
                                    Live
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button
                                    className="nav-link"
                                    id="completed-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#navs-top-completed"
                                    type="button"
                                    role="tab"
                                    aria-controls="navs-top-completed"
                                    aria-selected="false"
                                >
                                    Completed
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button
                                    className="nav-link"
                                    id="cancelled-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#navs-top-cancelled"
                                    type="button"
                                    role="tab"
                                    aria-controls="navs-top-cancelled"
                                    aria-selected="false"
                                >
                                    Cancelled
                                </button>
                            </li>
                        </ul>
                        <div className="tab-content">
                            <div className="tab-pane fade show active" id="navs-top-live" role="tabpanel">
                                <Helps helps={liveHelps} helpClicked={helpClicked} helpType={'live'} url={props.url}/>
                            </div>
                            <div className="tab-pane fade" id="navs-top-completed" role="tabpanel">
                                <Helps helps={completedHelps} helpClicked={helpClicked} helpType={'completed'} url={props.url}/>
                            </div>
                            <div className="tab-pane fade" id="navs-top-cancelled" role="tabpanel">
                                <Helps helps={cancelledHelps} helpClicked={helpClicked} helpType={'cancelled'} url={props.url}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>
}