import React, { useRef } from "react";
import Services from "./Services";

export default function Home(props) {
    let [slides, setSlides] = React.useState([]);
    let [services, setServices] = React.useState([]);
    let baseUrl = props.url;
    let [searchResult, setSearchResult] = React.useState([]);
    const [searchPlaceholder, setSearchPlaceholder] = React.useState("");
    let search = () => {
        let txt = document.getElementById('search-txt').value;
        if (txt) {
            fetch(props.url + 'search/', {
                method: 'POST',
                headers: props.headers,
                body: JSON.stringify({
                    'key_word': txt
                })
            }).then(res => res.json()).then(res => {
                if (res.data) {
                    setSearchResult(res.data)
                } else {
                    alert(res.message || 'Something went wrong!');
                }
            }).then(() => props.setShowMainSpinner(false));
        } else {
            document.getElementById('all-service-label').innerHTML = 'All services';
            fetch(props.url + 'category/', {
                method: 'GET',
                headers: props.headers,
            }).then(res => res.json()).then(res => {
                if (res.data) {
                    setSearchResult(res.data)
                } else {
                    alert(res.message || 'Something went wrong!');
                }
            }).then(() => props.setShowMainSpinner(false));
        }

    }


    const placeholderPhrases = [
        "Take my pup out",
        "Fix my place up",
        "Meal prep help",
        "Get my groceries"
    ];
    let placeholderIndex = 0;
    let intervalId = null;
    const inputRef = useRef(null);
    const textAnimation = async () => {
        const currentPhrase = placeholderPhrases[placeholderIndex];
        let charIndex = 0;

        // Typing animation
        for (let j = 0; j <= currentPhrase.length; j++) {
            setSearchPlaceholder(currentPhrase.substring(0, j));
            await new Promise((resolve) => setTimeout(resolve, 1300));
        }
        await new Promise((resolve) => setTimeout(resolve, 1300));
        for (let j = currentPhrase.length; j >= 0; j--) {
            setSearchPlaceholder(currentPhrase.substring(0, j));
            await new Promise((resolve) => setTimeout(resolve, 1000));
        }
        placeholderIndex = (placeholderIndex + 1) % placeholderPhrases.length;
    };

    React.useEffect(() => {
        if (document.getElementById('all-service-label')) {
            document.getElementById('all-service-label').innerHTML = '';
        }
        props.setShowMainSpinner(true);
        props.setPageTitle('Assisthand');
        fetch(props.url, {
            method: 'GET',
            headers: props.headers
        }).then(res => res.json()).then(res => {
            if (res.data) {
                setSlides(res.data.slides)
                if (res.data.has_live_help) {
                    props.setNotification('You have live help request(s)!');
                }
            } else {
                alert(res.message || 'Something went wrong!');
            }
        }).then(() => props.setShowMainSpinner(false));
        props.setShowMainSpinner(true);
        fetch(props.url + 'category/0', {
            method: 'GET',
            headers: props.headers
        }).then(res => res.json()).then(res => {
            if (res.data) {
                setServices(res.data);
            } else {
                alert(res.message || 'Something went wrong!');
            }
        })
            .catch((error) => {
                console.log("Error", error);
            })
            .finally(() => props.setShowMainSpinner(false));
        textAnimation();
        intervalId = setInterval(textAnimation, 6000);
        return () => clearInterval(intervalId);

    }, []);

    return <React.Fragment>

        {searchResult.length === 0 ? <React.Fragment>
            <br />
            <div className="row card rounded card-body shadow-sm" style={{ backgroundColor: 'white', fontSize: 'larger', color: 'black' }}>
                <br />
                <h5>Hi {props.name.split(' ')[0]}!</h5>
                <br />
                <h1 className="display-2 f-w-700">How can we assist you today?</h1>
                <br />
                <div className="input-group mb-3">

                    <input type="text" className="form-control bg-light btn-pill" placeholder={searchPlaceholder}
                        id="search-txt" aria-label="Search..." aria-describedby="button-addon2"
                        onFocus={() => clearInterval(intervalId)} onBlur={() => {
                            if (inputRef.current.value === "") {
                                setSearchPlaceholder(placeholderPhrases[0]);
                                placeholderIndex = 0;
                                intervalId = setInterval(textAnimation, 2500);
                            }
                        }}
                        ref={inputRef} />
                    <button className="btn btn-primary btn-pill" type="button" id="button-addon2"
                        onClick={() => search()}><i className="fa fa-search"></i>
                    </button>
                </div>
            </div>
            <div className="row card rounded card-body shadow-sm" style={{ backgroundColor: 'white' }}>
                <button className="btn btn-outline-primary btn-xs btn-pill" onClick={() => search()}
                    style={{ zIndex: '999', position: 'relative', width: '30%' }}>
                    see all &nbsp;<i className="fa fa-chevron-right"></i>
                </button>
                <br />
                <Services items={services} baseUrl={baseUrl} />
                <a href='/create' className="btn btn-primary btn-lg btn-user btn-block">
                    <i className="fa fa-plus-square-o"></i>
                    &nbsp;&nbsp;
                    Create your own
                </a>

            </div>
            <div className="row card rounded card-body shadow-sm" style={{ backgroundColor: 'white', fontSize: 'larger', color: 'black' }}>
                <div className="col-xl-7 col-13 rounded">
                    <div className="carousel slide rounded" id="carouselExampleControls" data-bs-ride="carousel">
                        <div className="carousel-inner rounded">
                            {slides && slides.map((slide, i) => {
                                let classes = "carousel-item rounded";
                                if (i === 0) {
                                    classes += " active";
                                }
                                return <div className={classes} key={i}>
                                    <img src={slide.display_img} className="d-block w-100 rounded" alt="..." style={{ maxHeight: "200px", objectFit: "cover" }} />

                                </div>
                            })}
                        </div>
                        <button className="carousel-control-prev" type="button"
                            data-bs-target="#carouselExampleControls" data-bs-slide="prev"><span
                                className="carousel-control-prev-icon" aria-hidden="true"></span><span
                                    className="visually-hidden">Previous</span></button>
                        <button className="carousel-control-next" type="button"
                            data-bs-target="#carouselExampleControls" data-bs-slide="next"><span
                                className="carousel-control-next-icon" aria-hidden="true"></span><span
                                    className="visually-hidden">Next</span></button>
                    </div>
                </div>
            </div>
        </React.Fragment> :
            <React.Fragment>
                <br />
                <h2 className="display-3" id="all-service-label">Services</h2>
                <div className="row card rounded card-body shadow-sm" style={{ backgroundColor: 'white', fontSize: 'larger', color: 'black' }}>
                    <Services items={searchResult} baseUrl={baseUrl} featured={true} />
                    <a href='/create' className="btn btn-primary btn-lg btn-user btn-block">
                        <i className="fa fa-plus-square-o"></i>
                        &nbsp;&nbsp;
                        Create your own
                    </a>
                </div>
            </React.Fragment>
        }
    </React.Fragment>
}
