import React, { Component } from "react";
import axios from "axios";

class UpdateStatus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            image: null,
        };
    }
    handleInputChange = (event) => {
        event.preventDefault();
        this.setState({
            image: event.target.files[0]
        })

    }
    submitForm = (event) => {
        event.preventDefault();
        let reqBody = new FormData();
        reqBody.append('comment', document.getElementById('comment').value);
        reqBody.append('help_id', this.props.helpId);
        if (this.state.image) {
            reqBody.append('status_img', this.state.image, this.state.image.name);
        }
        axios.post(this.props.url + 'task/status/',
            reqBody, {
            headers: {
                'content-type': 'multipart/form-data',
                'HELPER': this.props.cookies.get('helperMode') === 'true' ? true : false,
                Authorization: 'Token ' + this.props.cookies.get('user')['token']
            }
        }).then(res => {
            this.props.setNotification(res.data.message || 'Something went wrong!');
        })


    }
    render() {

        return <React.Fragment>
            <form className="modal-content" onSubmit={(event) => this.submitForm(event)}>
                <div className="modal-header">
                    <h5 className="modal-title" id="backDropModalTitle">Update help status</h5>
                    <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    ></button>
                </div>
                <div className="modal-body">
                    <div className="card-body">
                        <small className="text-light fw-semibold">Update status</small>
                        <dl className="row mt-2">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="basic-icon-default-message">Image (optional)</label>
                                <div className="input-group input-group-merge">
                                    <span id="basic-icon-default-message2" className="input-group-text"
                                    ><i className="bx bx-camera"></i
                                    ></span>
                                    <input type="file" name="image" className="form-control upload-image" placeholder="Image"
                                        onChange={(event) => this.handleInputChange(event)} />
                                </div>
                            </div>
                            <dt className="col-sm-3">Comment</dt>
                            <dd className="col-sm-9">
                                <textarea id="comment" className="form-control" required>

                                </textarea>
                            </dd>
                        </dl>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="submit" style={{ 'width': '100%' }} className="btn btn-outline-danger">
                        Update
                    </button>
                </div>
            </form>
        </React.Fragment>
    }
}

export default UpdateStatus;