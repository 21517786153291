import React from "react";

export default function FAQ(props) {

    return <React.Fragment>
        <div className="col-md mb-4 mb-md-0">
            <small className="text-dark fw-semibold">Click on a question to see the answer.</small>
            <div className="accordion mt-3" id="accordionExample">
                <div className="card accordion-item active">
                    <h2 className="accordion-header" id="headingOne">
                        <button
                            type="button"
                            className="accordion-button"
                            data-bs-toggle="collapse"
                            data-bs-target="#accordionOne"
                            aria-expanded="true"
                            aria-controls="accordionOne"
                        >
                            FAQ Question 1
                        </button>
                    </h2>

                    <div
                        id="accordionOne"
                        className="accordion-collapse collapse show"
                        data-bs-parent="#accordionExample"
                    >
                        <div className="accordion-body">
                            Lemon drops chocolate cake gummies carrot cake chupa chups muffin topping. Sesame snaps
                            icing
                            marzipan gummi bears macaroon dragée danish caramels powder. Bear claw dragée pastry topping
                            soufflé. Wafer gummi bears marshmallow pastry pie.
                        </div>
                    </div>
                </div>
                <div className="card accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                        <button
                            type="button"
                            className="accordion-button collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#accordionTwo"
                            aria-expanded="false"
                            aria-controls="accordionTwo"
                        >
                            FAQ Question 2
                        </button>
                    </h2>
                    <div
                        id="accordionTwo"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingTwo"
                        data-bs-parent="#accordionExample"
                    >
                        <div className="accordion-body">
                            Dessert ice cream donut oat cake jelly-o pie sugar plum cheesecake. Bear claw dragée oat
                            cake
                            dragée ice cream halvah tootsie roll. Danish cake oat cake pie macaroon tart donut gummies.
                            Jelly beans candy canes carrot cake. Fruitcake chocolate chupa chups.
                        </div>
                    </div>
                </div>
                <div className="card accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                        <button
                            type="button"
                            className="accordion-button collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#accordionThree"
                            aria-expanded="false"
                            aria-controls="accordionThree"
                        >
                            FAQ Question 3
                        </button>
                    </h2>
                    <div
                        id="accordionThree"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                    >
                        <div className="accordion-body">
                            Dessert ice cream donut oat cake jelly-o pie sugar plum cheesecake. Bear claw dragée oat
                            cake
                            dragée ice cream halvah tootsie roll. Danish cake oat cake pie macaroon tart donut gummies.
                            Jelly beans candy canes carrot cake. Fruitcake chocolate chupa chups.
                        </div>
                    </div>
                </div>
                <div className="card accordion-item">
                    <h2 className="accordion-header" id="headingFour">
                        <button
                            type="button"
                            className="accordion-button collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#accordionFour"
                            aria-expanded="false"
                            aria-controls="accordionFour"
                        >
                            FAQ Question 4
                        </button>
                    </h2>
                    <div
                        id="accordionFour"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingFour"
                        data-bs-parent="#accordionExample"
                    >
                        <div className="accordion-body">
                            Dessert ice cream donut oat cake jelly-o pie sugar plum cheesecake. Bear claw dragée oat
                            cake
                            dragée ice cream halvah tootsie roll. Danish cake oat cake pie macaroon tart donut gummies.
                            Jelly beans candy canes carrot cake. Fruitcake chocolate chupa chups.
                        </div>
                    </div>
                </div>
                <div className="card accordion-item">
                    <h2 className="accordion-header" id="headingFive">
                        <button
                            type="button"
                            className="accordion-button collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#accordionFive"
                            aria-expanded="false"
                            aria-controls="accordionFive"
                        >
                            FAQ Question 5
                        </button>
                    </h2>
                    <div
                        id="accordionFive"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingFive"
                        data-bs-parent="#accordionExample"
                    >
                        <div className="accordion-body">
                            Dessert ice cream donut oat cake jelly-o pie sugar plum cheesecake. Bear claw dragée oat
                            cake
                            dragée ice cream halvah tootsie roll. Danish cake oat cake pie macaroon tart donut gummies.
                            Jelly beans candy canes carrot cake. Fruitcake chocolate chupa chups.
                        </div>
                    </div>
                </div>

                <div className="card accordion-item">
                    <h2 className="accordion-header" id="headingSix">
                        <button
                            type="button"
                            className="accordion-button collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#accordionSix"
                            aria-expanded="false"
                            aria-controls="accordionSix"
                        >
                            FAQ Question 6
                        </button>
                    </h2>
                    <div
                        id="accordionSix"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingSix"
                        data-bs-parent="#accordionExample"
                    >
                        <div className="accordion-body">
                            Oat cake toffee chocolate bar jujubes. Marshmallow brownie lemon drops cheesecake. Bonbon
                            gingerbread marshmallow sweet jelly beans muffin. Sweet roll bear claw candy canes oat cake
                            dragée caramels. Ice cream wafer danish cookie caramels muffin.
                        </div>
                    </div>
                </div>
                 <div className="card accordion-item">
                    <h2 className="accordion-header" id="headingSeven">
                        <button
                            type="button"
                            className="accordion-button collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#accordionSeven"
                            aria-expanded="false"
                            aria-controls="accordionSeven"
                        >
                            FAQ Question 7
                        </button>
                    </h2>
                    <div
                        id="accordionSeven"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingSeven"
                        data-bs-parent="#accordionExample"
                    >
                        <div className="accordion-body">
                            Dessert ice cream donut oat cake jelly-o pie sugar plum cheesecake. Bear claw dragée oat
                            cake
                            dragée ice cream halvah tootsie roll. Danish cake oat cake pie macaroon tart donut gummies.
                            Jelly beans candy canes carrot cake. Fruitcake chocolate chupa chups.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>
}