import React from "react";
import Cookies from 'universal-cookie';
import Frame from "./components/Frame";
import Login from "./components/main/Login";

function App() {
    let url = 'https://assisthand-server.elvesonthecloud.com/';
    if (!process.env.REACT_APP_PRODUCTION) {
        url = 'http://localhost:8000/';
    }
    const cookies = new Cookies();
    if (cookies.get('user')) {
        return <Frame url={url} cookies={cookies}/>;
    } else {
        return <Login url={url} cookies={cookies}/>;
    }
}

export default App;
