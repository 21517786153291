import React from "react";
export default function Helps(props) {
    let badgeDict = {
        'live': <span className="badge bg-success">Live</span>,
        'completed': <span className="badge bg-secondary">Completed</span>,
        'cancelled': <span className="badge bg-danger">Cancelled</span>,
    }
    let alertDict = {
        'live': 'border-success',
        'completed':'border-primary',
        'cancelled':'border-danger',
    }

    if (props.helps.length > 0) {
        return <React.Fragment>
            {props.helps.map(help => {
                return  <div key={help.id} className="help-card">
                <div className="card-row">
                    <div className="img-col">
                        <div className="card-image">
                            <img src={props.url+help.service.img} alt="display" />
                        </div>
                    </div>
                    <div className="title-col">
                        <div className="help-title">{help.service.name}</div>
                        <div className="details">{help.description}</div>
                        <div className="status-container">
                            <div className="status">Scheduled</div>
                        </div>
                    </div>
                </div>
                <div className="card-row">
                    <div className="card-col">
                        <div className="date-time">Sun, Oct 16 at 18:30pm</div>
                        <div className="amount">Amount : ₹{help.budget_amount}</div>
                    </div>
                    <div className="card-col">
                        <button className="book-again-button">Book Again</button>
                    </div>
                </div>
                <hr className="card-divider" />
                <button className="expand-button">&#9660;</button>
                <div className="expanded-details">
                    {/* expanded details here */}
                </div>
            </div>
            })}
        </React.Fragment>
    }
    else {
        return <div className={"alert border alert-light "+alertDict[props.helpType]} style={{ 'marginTop': '1rem' }}>
            No helps found.
        </div>
    }
}