import React from "react";

export default function Statuses(props) {
    return <React.Fragment>
        <br />
        <h6 className="mt-2 text-muted">Status updates</h6>
        {props.statuses && props.statuses.map(status => {
            if (status.upload_img) {
                return <div className="col-md-6 col-lg-4 mb-3" key={status.created_at}>
                    <div className="card h-100">

                        <div className="card-body">
                            <div className="container">
                                <div className="alert alert-warning border" >{status.created_at.replace('T', ' ').split('.')[0]}</div>
                            </div>
                            <img
                                className="img-fluid d-flex mx-auto my-4"
                                src={props.baseUrl + status.upload_img}
                                alt="Card image cap"
                            />
                            <p className="card-text">{status.comment}</p>
                        </div>
                    </div>
                </div>
            }
            else {
                return <div className="card mb-4">

                    <div className="card-body">
                        <div className="container">
                            <div className="alert alert-warning border" >{status.created_at.replace('T', ' ')}</div>
                        </div>
                        <p className="card-text">
                            {status.comment}
                        </p>
                    </div>
                </div>
            }
        })}
        <br />
    </React.Fragment>
}