import React from "react";
import AddService from "../utils/AddService";
import { MainContext } from "../Frame";

export default function HelperService(props) {
    const { setShowMainSpinner } = React.useContext(MainContext);
    let baseUrl = props.url + 'user/helper-service/';
    let [myServices, setMyServices] = React.useState([]);
    let [services, setServices] = React.useState([]);
    let [addServices, setAddServices] = React.useState([]);
    let [toDelete, setToDelete] = React.useState([]);
    function updateService() {
        setShowMainSpinner(true);
        let toAddServices = [];
        var items = document.getElementsByClassName("add-new");
        for (var i = 0; i < items.length; i++) {
            toAddServices.push(items[i].value);
        }
        let payload = {
            'service_to_delete': toDelete,
            'services': toAddServices
        }
        fetch(baseUrl, {
            method: 'POST',
            headers: props.headers,
            body: JSON.stringify(payload)
        }).then(res => res.json()).then(res => {
            props.setNotification(res.message || 'Something went wrong!');
            fetchMyServices();
            setAddServices([])
        }).then(setShowMainSpinner(false));
    }
    function addNew(item) {
        let d = new Date();
        let r = Math.random().toString() + Math.random().toString();
        setAddServices(addServices.concat([<AddService removeCurrent={removeCurrent} key={d.getTime() + '-' + r} id={d.getTime() + '-' + r} deleteFromList={deleteFromList} id={1} />]))
    }
    function removeCurrent(id){
        document.getElementById(id).remove()
        document.getElementById("trash-"+id).remove()
    }
    function deleteFromList(event, title,id) {
        setToDelete(toDelete.concat([title]))
        document.getElementById(id).innerHTML = '';
    }
    function fetchMyServices(){
        setShowMainSpinner(true);
        fetch(baseUrl, {
            method: 'GET',
            headers: props.headers
        }).then(res => res.json()).then(res => {
            if (res.data) {
                setMyServices(res.data);
            } else {
                props.setNotification(res.message || 'Something went wrong!');
            }
        }).then(()=>setShowMainSpinner(false));
    }
    React.useEffect(() => {
        props.setPageTitle('Add your skills');
        fetch(props.url + 'service/', {
            method: 'GET',
            headers: props.headers
        }).then(res => res.json()).then(res => {
            if (res.data) {
                setServices(res.data);

            } else {
                props.setNotification(res.message || 'Something went wrong!');
            }
        })
        fetchMyServices();
    }, []);

    return <React.Fragment>
        <div className="card">
            <div className="card-body">
                <div className="row">
                    <div className="col-lg-6">
                        <small className="text-light fw-semibold">SAVE CHANGES TO APPLY</small>
                        <br />
                        <button type="button" className="btn btn-sm btn-primary" onClick={() => updateService()}>Save changes</button>
                        <button type="button" onClick={() => addNew()} className="btn btn-sm border">
                            <span><i className="bx bx-add-to-queue"></i>Add new</span>
                        </button>
                        <div className="demo-inline-spacing mt-3">
                            <ul className="list-group">
                                {addServices}
                                {myServices && myServices.map(service => {
                                    return <li key={service.title} id={service.id} className="list-group-item d-flex justify-content-between align-items-center">
                                        {service.title}
                                        <span onClick={(event, title,id) => deleteFromList(event, service.title,service.id)} className="badge bg-danger"><i className="bx bx-trash"></i></span>
                                    </li>
                                })}
                            </ul>
                        </div>
                        <datalist id="services">
                            {services && services.map(service => {
                                return <option className="form-control" key={service.title} value={service.title} />
                            })}
                        </datalist>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>
}