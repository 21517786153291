import React from "react";
import { MainContext } from "../Frame";

export default function Payment(props) {
    const helpId = window.location.href.split('/')[4];
    let ourUrl = 'https://assisthand-ui.netlify.app';
    if (!process.env.REACT_APP_PRODUCTION) {
        ourUrl = 'http://localhost:3000';
    }
    const { setShowMainSpinner } = React.useContext(MainContext);
    let [keyId, setKeyId] = React.useState(null);
    let [amount, setAmount] = React.useState(null);
    let [rPayOrderId, setRPayOrderId] = React.useState(null);
    let [fullName, setFullName] = React.useState(null);
    let [contactNo, setContactNo] = React.useState(null);
    let [email, setEmail] = React.useState(null);
    let [subTotalAmount, setSubTotalAmount] = React.useState(null);
    let [totalAmount, setTotalAmount] = React.useState(null);
    let [taxAmount, setTaxAmount] = React.useState(null);

    React.useEffect(() => {
        setShowMainSpinner(true);
        fetch(props.url + 'task/payment/' + helpId, {
            method: 'GET',
            headers: props.headers
        }).then(res => res.json()).then(res => {
            if (res.data) {
                setKeyId(res.data.key_id);
                setRPayOrderId(res.data.order_id);
                setFullName(res.data.name);
                setAmount(res.data.amount);
                setTotalAmount(res.data.total_amount);
                setTaxAmount(res.data.tax_amount);
                setSubTotalAmount(res.data.sub_total_amount);
                setEmail(res.data.email);
                setContactNo(res.data.contact_no);
            } else {
                alert(res.message || 'Something went wrong!');
            }
        }).then(() => setShowMainSpinner(false));
    }, []);
    return <React.Fragment>
        <div className="card border shadow-lg" align="center">
            <br />
            <center>
                <div className="badge badge-sm bg-success" style={{ 'width': '70%' }}>
                    Summary
                </div>

                <br />
                <br />
                <div className="table-responsive text-nowrap">
                    <table className="table table-sm border">

                        <tbody className="table-border-bottom-0">
                            <tr>
                                <td><i className="fab fa-angular fa-lg text-danger me-3"></i> <strong>Sub total</strong></td>
                                <td>:</td>
                                <td><i className="bx bx-rupee"></i>{subTotalAmount}</td>
                            </tr>
                            <tr>
                                <td><i className="fab fa-angular fa-lg text-danger me-3"></i> <strong>Tax</strong></td>
                                <td>:</td>
                                <td><i className="bx bx-rupee"></i>{taxAmount}</td>
                            </tr>
                            <tr>
                                <td><i className="fab fa-angular fa-lg text-danger me-3"></i> <strong>Tota to pay</strong></td>
                                <td>:</td>
                                <td><i className="bx bx-rupee"></i>{totalAmount}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <br />
                <br />
            </center>

            <form method="POST" action="https://api.razorpay.com/v1/checkout/embedded">
                <input type="hidden" name="key_id" defaultValue={keyId} />
                <input type="hidden" name="amount" defaultValue={amount} />
                <input type="hidden" name="order_id" defaultValue={rPayOrderId} />
                <input type="hidden" name="name" defaultValue="AssistHand" />
                <input type="hidden" name="description" defaultValue="AssistHand payment" />
                <input type="hidden" name="image" defaultValue="https://www.elvesonthecloud.com/assets/img/hero-img.png" />
                <input type="hidden" name="prefill[name]" defaultValue={fullName} />
                <input type="hidden" name="prefill[contact]" defaultValue={contactNo} />
                <input type="hidden" name="prefill[email]" defaultValue={email} />
                <input type="hidden" name="notes[shipping address]" defaultValue="L-16, Dummy address, Dummy address, New Delhi - 110001" />
                <input type="hidden" name="callback_url" defaultValue={props.url + 'task/confirm-payment/' + helpId} />
                <input type="hidden" name="cancel_url" defaultValue={ourUrl + '/payment-cancel'} />
                <button className="btn btn-success">Pay now</button>
                <br />
                <br />
                <br />
                <a href={document.referrer} className="btn btn-sm btn-outline-danger">Cancel</a>
                <br />
                <br />
            </form>
        </div>


    </React.Fragment>
}