export default function DisplayMessage(props) {
    const contentDict = {
        'pmt-success': <div className="container-fluid">
            <div className="alert alert-success">
                Hoorayyy!!!!
            </div>
            <div className="alert alert-light border">
                <small>
                    The payment has succeeded!
                </small>
            </div>
        </div>,
        'pmt-cancel': <div className="container-fluid">
            <div className="alert alert-success">
                Oops!
            </div>
            <div className="alert alert-light border">
                <small>
                    The payment has been cancelled!
                </small>
            </div>
            <button type="button" className="btn btn-success">Click to retry</button>
        </div>
    }
    return contentDict[props.content]
}