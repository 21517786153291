import React from "react";

export default function Spinner() {
    return <React.Fragment>
        <div align="center" id="spinner">
            <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
            <br />
            <h4 className="text-muted">Loading...</h4>
            <br />
        </div>
    </React.Fragment>
}