import React from "react";
import Stars from "../utils/Stars";

export default function Review(props) {
    let [reviews, setReviews] = React.useState([]);
    React.useEffect(() => {
        fetch(props.url + 'user/my-review', {
            method: 'GET',
            headers: props.headers
        }).then(res => res.json()).then(res => {
            if (res.data) {
                setReviews(res.data);
            } else {
                props.setNotification(res.message || 'Something went wrong!');
            }
        })

        props.setPageTitle('My reviews');
    }, []);
    return <div className="container">
        <div className="row">
            {reviews.map(item => {
                return <React.Fragment key={item.id}>
                    <div className="card border">
                        <div className="p-3">

                            <img src="https://image.ibb.co/jw55Ex/def_face.jpg" style={{ 'width': '4rem', 'marginTop': '1rem' }}
                                className="img img-rounded" alt="User" />
                            <br />
                            <strong>{item.created_by}</strong>
                            <Stars count={item.stars} />

                            <div className="clearfix"></div>
                            <p>{item.comment}
                            </p>
                        </div>
                    </div>
                   <div>
                    &nbsp;
                   </div>
                </React.Fragment>
            })}
        </div>
    </div>
}