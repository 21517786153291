import React, {Component} from "react";
import axios from "axios";


class BeAHelper extends Component {
    constructor(props) {
        super(props);
        this.state = {
            obj: {},
            formData: {},
            idProofImg: null,
            underReview: false,
            selfieImg: null,
            eduLevels: [],
        };
    }

    idProofImgChange = (event) => {
        event.preventDefault();
        this.setState({
            idProofImg: event.target.files[0]
        })
    };
    triggerIdProofOTP = (event) => {
        fetch(this.props.url + 'user/verify-id-proof/' + document.getElementById('id-proof-no').value, {
            method: 'GET',
            headers: this.props.headers
        }).then(res => res.json()).then(res => {
            if (res.data) {
                this.props.setNotification('OTP verification coming soon...')
                document.getElementById('register-btn').disabled = false;
                // document.getElementById('otp-div').style.display = '';
                document.getElementById('otp-div').remove();
            } else {
                this.props.setNotification(res.message || 'Something went wrong!');
            }
        })
    }
    verifyIdProofOTP = (event) => {
        fetch(this.props.url + 'user/verify-id-proof/' + document.getElementById('id-proof-no').value, {
            method: 'POST',
            headers: this.props.headers
        }).then(res => res.json()).then(res => {
            if (res.data) {
                document.getElementById('register-btn').disabled = false;
                document.getElementById('otp-div').remove();
            } else {
                this.props.setNotification(res.message || 'Something went wrong!');
            }
        })
    }
    selfieImgChange = (event) => {
        event.preventDefault();
        this.setState({
            selfieImg: event.target.files[0]
        })
    };

    submitForm = (event) => {
        event.preventDefault();
        this.props.setShowMainSpinner(true);
        let reqBody = new FormData();
        reqBody.append('first_name', document.getElementById('first-name').value);
        reqBody.append('last_name', document.getElementById('last-name').value);
        reqBody.append('email', document.getElementById('email').value);
        reqBody.append('edu_level', document.getElementById('edu-level').value);
        reqBody.append('id_proof_no', document.getElementById('id-proof-no').value);
        reqBody.append('has_vehicle', document.getElementById('has-vehicle').value);
        reqBody.append('id_proof_img', this.state.idProofImg, this.state.idProofImg.name);
        reqBody.append('selfie_img', this.state.selfieImg, this.state.selfieImg.name);

        axios.post(this.props.url + 'user/be-a-helper/',
            reqBody, {
                headers: {
                    'content-type': 'multipart/form-data',
                    Authorization: 'Token ' + this.props.cookies.get('user')['token']
                }
            }).then(res => {
            if (res.data.error) {
                this.props.setNotification(res.data.message || 'Something went wrong!');
            } else {
                this.props.setNotification(res.data.message);
                setTimeout(function () {
                    window.location.reload();
                }, 2000);
            }
            this.props.setShowMainSpinner(false);
        })
    }

    componentDidMount() {
        this.props.setPageTitle('Start helping!');
        fetch(this.props.url + 'education-level/', {
            method: 'GET',
            headers: this.props.headers
        }).then(res => res.json()).then(res => {
            if (res.under_review) {
                this.setState({
                    underReview: true,
                })
            } else if (res.data) {
                this.setState({
                    eduLevels: res.data
                });

            } else {
                this.props.setNotification(res.message || 'Something went wrong!');
            }
        })
        fetch(this.props.url + 'user/profile/', {
            method: 'GET',
            headers: this.props.headers,
        }).then(res => res.json()).then(res => {
            if (res.data) {
                this.setState({
                    obj: res.data
                });
            } else {
                if (!window.location.href.includes('profile')) {
                    window.location.href = '/profile';
                }
            }
        })
    }

    render() {
        if (this.state.underReview) {
            return <React.Fragment>
                <div className="alert alert-warning card border shadow-lg" style={{'marginTop': '2rem'}}>
                    Your application is under review. Please check again later.
                </div>
                <center>
                    <br/>
                    <br/>
                    <br/>
                    <img alt="placeholder" style={{'width': '7rem'}} className="img-fluid shadow-lg rounded"
                         src="static/assets/img/icons/unicons/chart.png"/>
                </center>
            </React.Fragment>
        } else {
            return <React.Fragment>
                <div className="alert alert-light border border-warning">
                    You are not registered as a helper yet. Please submit the below form to proceed.
                </div>
                <div className=" mb-4">
                    <div className="d-flex justify-content-between align-items-center">
                        <h5 className="mb-0">Register now</h5>
                        <small className="text-muted float-end">Start earning soon!</small>
                    </div>
                    <br/>
                    <div className="container">
                        <form onSubmit={(event) => this.submitForm(event)}>
                            <div className="mb-3">
                                <label className="form-label" htmlFor="basic-icon-default-company">Aadhaar
                                    number</label>
                                <div className="input-group input-group-merge">
                                        <span id="basic-icon-default-company2" className="input-group-text"
                                        ><i className="bx bx-id-card"></i
                                        ></span>
                                    <input
                                        type="text"
                                        id="id-proof-no"
                                        className="form-control"
                                        placeholder="XXXX-XXXX-XXXX-XXXX"
                                        required
                                    />
                                    <button type={"button"} className={"btn btn-outline-success btn-sm"}
                                            onClick={() => this.triggerIdProofOTP()}>
                                        Verify
                                    </button>
                                </div>
                                <div className="input-group input-group-merge" id={"otp-div"}
                                     style={{'display': 'none'}}>
                                        <span id="basic-icon-default-company3" className="input-group-text"
                                        ><i className="bx bx-id-card"></i
                                        ></span>
                                    <input
                                        type="number"
                                        id="otp-no"
                                        className="form-control"
                                        placeholder="XXXXXXX"
                                        required

                                    />
                                    <button type={"button"} className={"btn btn-outline-success btn-sm"}
                                            onClick={() => this.verifyIdProofOTP()}>
                                        Verify
                                    </button>
                                </div>
                            </div>


                            <div className="mb-3">
                                <label className="form-label" htmlFor="basic-icon-default-message">Aadhaar
                                    image</label>
                                <div className="input-group input-group-merge">
                                        <span id="basic-icon-default-message2" className="input-group-text"
                                        ><i className="bx bx-id-card"></i
                                        ></span>
                                    <input type="file" name="images" className="form-control upload-image"
                                           placeholder="Image"
                                           required
                                           onChange={(event) => this.idProofImgChange(event)}/>
                                </div>
                            </div>
                            <div className="mb-3">
                                <label className="form-label" htmlFor="basic-icon-default-fullname">First
                                    Name</label>
                                <div className="input-group input-group-merge">
                                        <span id="basic-icon-default-fullname2" className="input-group-text"><i
                                            className="bx bx-user"></i></span>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="first-name"
                                        placeholder="John"
                                        defaultValue={this.state.obj.first_name}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="mb-3">
                                <label className="form-label" htmlFor="basic-icon-default-company">Last Name</label>
                                <div className="input-group input-group-merge">
                                        <span id="basic-icon-default-company1" className="input-group-text"
                                        ><i className="bx bx-user"></i
                                        ></span>
                                    <input
                                        type="text"
                                        id="last-name"
                                        className="form-control"
                                        defaultValue={this.state.obj.last_name}
                                        placeholder="Doe"
                                        required
                                    />
                                </div>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Education level</label>
                                <select className="form-select" id="edu-level">
                                    <option value={''} defaultValue={true}>choose level</option>
                                    {this.state.eduLevels && this.state.eduLevels.map(item => {
                                        return <option key={item.id} value={item.id}>{item.name}</option>;
                                    })}
                                </select>
                            </div>
                            <div className="d-flex form-check form-switch mb-3 align-items-center">
                                <input className="form-check-input" type="checkbox"
                                       id="has-vehicle"/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <label className="form-check-label" htmlFor="vehicle-needed">Do you have a
                                    vehicle?</label>
                            </div>
                            <div className="mb-3">
                                <label className="form-label" htmlFor="basic-icon-default-email">Email</label>
                                <div className="input-group input-group-merge">
                                    <span className="input-group-text"><i className="bx bx-envelope"></i></span>
                                    <input
                                        type="email"
                                        id="email"
                                        className="form-control"
                                        defaultValue={this.state.obj.email}
                                        placeholder="john.doe@example.com"
                                        required
                                    />
                                </div>

                            </div>
                            <div className="mb-3">
                                <label className="form-label" htmlFor="basic-icon-default-message">Selfie
                                    image</label>
                                <div className="input-group input-group-merge">
                                        <span id="basic-icon-default-message2" className="input-group-text"><i
                                            className="bx bx-user-pin"></i></span>
                                    <input type="file" name="images" className="form-control upload-image"
                                           placeholder="Image"
                                           required id="selfie-img"
                                           onChange={(event) => this.selfieImgChange(event)}/>

                                </div>
                            </div>

                            <div className="mb-3">
                                <input type="checkbox" id="terms" required/>
                                <label className="form-check-label" htmlFor="terms">
                                    By clicking this checkbox, I agree to the <a target="_blank"
                                                                                 href={this.props.url.slice(0, -4) + 'helper-terms-and-conditions'}>terms
                                    & conditions.</a>
                                </label>
                                <div className="rectangle-box">
                                    Please read the terms and conditions
                                </div>
                            </div>
                            <div className="row d-flex align-items-center justify-content-center">
                                <button type="submit" disabled={true} style={{'width': '35%'}} id={"register-btn"}
                                        className="btn btn-sm btn-primary">Register
                                </button>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <button type="button" style={{'width': '35%'}} className="btn btn-sm btn-danger">Not
                                    now
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </React.Fragment>
        }
    }
}

export default BeAHelper;
