import React from "react";

export default function DateTime(props) {
    const handleDateOptionClick = (event) => {
        event.target.classList.toggle('selected');
        props.setScheduledDate(event.target.textContent)
    };

    const handleTimeSlotClick = (event) => {
        event.target.classList.toggle('selected');
        props.setScheduledTime(event.target.textContent)
    };

    const renderDateOptions = () => {
        const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        const dateOptions = [];

        for (let i = 0; i < 3; i++) {
            const date = new Date();
            date.setDate(date.getDate() + i);
            const dayName = dayNames[date.getDay()];
            const formattedDate = date.toLocaleDateString('en-US', {day: 'numeric', month: 'short'});

            dateOptions.push(
                <button
                    type={"button"}
                    key={i}
                    className="date-option"
                    onClick={handleDateOptionClick}
                >
                    {`${dayName}\n${formattedDate}`}
                </button>
            );
        }

        return dateOptions;
    };

    const renderTimeSlots = () => {
        const timeSlots = [];
        const startTime = 9 * 60; // 9:00 AM in minutes
        const endTime = 21 * 60; // 9:00 PM in minutes
        const interval = 30; // 30-minute interval

        for (let i = startTime; i < endTime; i += interval) {
            const hours = Math.floor(i / 60);
            const minutes = i % 60;
            const ampm = hours >= 12 ? "PM" : "AM";
            const displayHours = hours % 12 || 12;

            timeSlots.push(
                <button
                    key={i}
                    type={"button"}
                    className="time-slot"
                    onClick={handleTimeSlotClick}
                >
                    {`${displayHours}:${String(minutes).padStart(2, '0')} ${ampm}`}
                </button>
            );
        }

        return timeSlots;
    };
    return <div className="container">
        <h2 className="title"><b>Get service on</b></h2>
        <br/>
        <div className="date-options">{renderDateOptions()}</div>
        <br/>
        <h3 className="title"><b>Select start time of service</b></h3>
        <div className="time-slots">{renderTimeSlots()}</div>
    </div>
}