import React from "react";

export default function Login(props) {
    document.body.style.overflow = 'hidden';
    let [OTPState, setOTPState] = React.useState(false);
    let [msg, setMsg] = React.useState(null);
    let login = (resp) => {
        if (resp.data) {
            props.cookies.set('user', resp.data, { path: '/' });
        } else {
            if (resp.error) {
                console.log(resp.message)
            }
            alert('Failed to authenticate!');
        }
        window.location.reload();
    }

    let authFunction = () => {
        setMsg(null);
        if (document.getElementById('contact_no').value.length !== 10) {
            setMsg('Invalid mobile number!');
            return false;
        }
        document.getElementById('login-loader').style.display = '';
        setTimeout(function () {
            setOTPState(true);
            document.getElementById('otp').disabled = false;
            document.getElementById('contact_no').disabled = true;
            document.getElementById('login-loader').style.display = 'none';
        }, 1000);
        fetch(props.url + 'user/login/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'contact_no': document.getElementById('contact_no').value
            }),
        }).then(resp => resp.json()).then(resp => {
            if (!resp.data) {
                if (resp.error) {
                    console.log(resp.message)
                }
                alert('Unable to login!');
                props.cookies.remove('user', { path: '/' });
                // window.location.reload();
            }
        })
    }
    let OTPFunction = () => {
        document.getElementById('login-loader').style.display = '';
        fetch(props.url + 'user/login/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'contact_no': document.getElementById('contact_no').value,
                'otp': document.getElementById('otp').value
            }),
        }).then(resp => resp.json()).then(resp => {
            if (resp.success) {
                login(resp); 
            } else if(resp.error) {
                alert(resp.message)
            }
        });
    }

    return <div className="container">
        <br />
        <br />
        <br />
        <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">

                <div className="card mb-3">

                    <div className="card-body">

                        <div className="pt-4 pb-2">
                            <h5 className="card-title text-center pb-0 fs-4">Login to Your Account</h5>
                            <p className="text-center small">Enter your mobile number</p>
                        </div>

                        <form className="row g-3 needs-validation" noValidate>

                            <div className="form-group">
                                <input type="number" className="form-control"
                                    id="contact_no" placeholder="Enter your mobile number" />
                            </div>
                            <div className="form-group">
                                <input type="number" className="form-control"
                                    id="otp" placeholder="One Time Password" disabled={true} />
                            </div>
                            <center>
                                <div style={{ 'display': 'none' }} id="login-loader"
                                    className="spinner-border" role="status">
                                </div>
                                <br />
                                <br />

                                {OTPState && <React.Fragment>
                                    <small>
                                        Check your mobile for an otp.
                                    </small>
                                    <br />
                                </React.Fragment>}
                                {msg && <strong>
                                    <small className="text-danger">
                                        {msg}
                                    </small>
                                </strong>}
                            </center>
                            {OTPState && <button type="button" onClick={() => OTPFunction()}
                                className="btn btn-primary btn-user btn-block">
                                Verify
                            </button>}
                            {!OTPState && <button onClick={() => authFunction()} type="button"
                                className="btn btn-primary btn-user btn-block">
                                Send OTP
                            </button>}
                            <hr />
                            <div className="d-flex justify-content-between align-items-center">
                                <span onClick={() => alert('coming soon...')}>
                                    <div className="flex-shrink-0">
                                        <img src="/assets/img/social/google.png" alt="google"
                                            className="me-3"
                                            height="30" />
                                    </div>

                                </span>
                                <span onClick={() => alert('coming soon...')}>
                                    <div className="flex-shrink-0">
                                        <img src="/assets/img/social/facebook.png" alt="facebook"
                                            className="me-3"
                                            height="30" />
                                    </div>
                                </span>
                                <span onClick={() => alert('coming soon...')}>
                                    <div className="flex-shrink-0">
                                        <img src="/assets/img/social/whatsapp.png" alt="whatsapp"
                                            className="me-3"
                                            height="30" />
                                    </div>
                                </span>
                            </div>
                            <br />
                            <br />
                            <br />
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
}