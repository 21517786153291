import React from "react";
import LiveHelp from "./LiveHelp";
import Home from './Home';
import { MainContext } from "../Frame";

export default function Landing(props) {
    const { setShowMainSpinner } = React.useContext(MainContext);
    
    if (props.helperMode) {
        return <LiveHelp setShowMainSpinner={setShowMainSpinner} setNotification={props.setNotification} setPageTitle={props.setPageTitle} url={props.url} cookies={props.cookies} customAlert={props.customAlert}
            headers={props.headers} />
    }
    else {
        return <Home hasLiveHelp={props.hasLiveHelp} setShowMainSpinner={setShowMainSpinner} setNotification={props.setNotification} setPageTitle={props.setPageTitle} url={props.url} cookies={props.cookies} customAlert={props.customAlert}
                     headers={props.headers}  name={props.name}/>
    }
}