import React from "react";

export default function Services(props) {
    function createTask(sId) {
        window.location.href = '/create/' + sId.toString();
    }

    return <div className="container">
        <div className="row size-column">
            <div className="col-xxl-10 col-md-12 box-col-8 grid-ed-12">
                <div className="row">
                    <div className="col-xxl-5 col-md-7 box-col-7">
                        <div className="row">
                            {props.items.map(service => {
                                return <div className="col-3 col-3" key={service.id}
                                    onClick={() => createTask(service.id)} style={{ borderRadius:'50%' ,height: "60px" }}>
                                    <img src={service.display_img}
                                        alt="Card cap" style={{ maxHeight: "40px", objectFit: "cover", borderRadius:'50%'  }} />
                                        {/* <small style={{fontSize:'10px'}}>{service.name}</small> */}
                                </div>
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}