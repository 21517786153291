import React, { createContext } from 'react';
import { useRoutes } from 'hookrouter';
import Landing from './main/Landing';
import Create from "./main/Create";
import Profile from "./main/Profile";
import Support from "./main/Support";
import Wallet from "./main/Wallet";
import FAQ from "./main/FAQ";
import BeAHelper from "./main/BeAHelper";
import History from "./main/History";
import Payment from "./main/Payment";
import HelperService from "./main/HelperService";
import DisplayMessage from "./utils/DisplayMessage";
import Review from "./main/Review";

export const MainContext = createContext();

export default function Frame(props) {
    let [pageTitle, setPageTitle] = React.useState('AssistHand');
    let [showMainSpinner, setShowMainSpinner] = React.useState(false);
    let [displayImg, setDisplayImg] = React.useState('');
    let [hasLiveHelp, setHasLiveHelp] = React.useState(false);
    let [name, setName] = React.useState('');
    let [contactNo, setContactNo] = React.useState('');
    let [email, setEmail] = React.useState('');
    let [notificationList, setNotificationList] = React.useState(null);
    let [notification, setNotification] = React.useState(false);
    let helperMode = props.cookies.get('helperMode');
    if (helperMode) {
        console.log(helperMode)
        helperMode = helperMode.toString() === 'true';
    }
    let logOut = () => {
        let r = window.confirm('Are you sure?');
        if (r) {
            props.cookies.remove('user', { path: '/' });
            window.location.reload();
        }
    }
    let headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + props.cookies.get('user')['token'],
        'HELPER': props.cookies.get('helperMode') === 'true' ? true : false
    }

    function customAlert(msg) {
        document.getElementById('alert-msg').innerHTML = msg;
        document.getElementById('alert-toggle').click();
    }



    function deleteNotification(notifId) {
        fetch(props.url + 'user/delete-notification/' + notifId, {
            method: 'GET',
            headers: headers
        }).then(res => res.json()).then(res => {
            if (!res.data) {
                customAlert(res.message || 'Something went wrong!');
            }
        }).then(() => {
            document.getElementById('notification-bell').click();
        })
    }

    function toggleOnHelperMode() {
        if (helperMode) {
            document.getElementById('flexSwitchCheckDefault').defaultChecked = false;
            props.cookies.set('helperMode', false, { path: '/' });
            window.location.href = '/';
        } else {
            let r = window.confirm('Switch to helper mode?');
            if (r) {
                fetch(props.url + 'task/help-requests/', {
                    method: 'GET',
                    headers: headers
                }).then(res => res.json()).then(res => {
                    if (res.data) {
                        props.cookies.set('helperMode', true, { path: '/' });
                        window.location.href = '/';
                    } else if (res.data === false) {
                        window.location.href = '/be-a-helper';
                    } else {
                        customAlert(res.message || 'Something went wrong!');
                    }

                });
            } else {
                document.getElementById('flexSwitchCheckDefault').checked = false;
            }
        }
    }

    let contextVars = {
        'setShowMainSpinner': setShowMainSpinner,
    }
    React.useEffect(() => {
        window.addEventListener('error', e => {
            if (e.message === 'ResizeObserver loop completed with undelivered notifications.') {
                const resizeObserverErrDiv = document.getElementById(
                    'webpack-dev-server-client-overlay-div'
                );
                const resizeObserverErr = document.getElementById(
                    'webpack-dev-server-client-overlay'
                );
                if (resizeObserverErr) {
                    resizeObserverErr.setAttribute('style', 'display: none');
                }
                if (resizeObserverErrDiv) {
                    resizeObserverErrDiv.setAttribute('style', 'display: none');
                }
            }
        });
        fetch(props.url + 'user', {
            method: 'GET',
            headers: headers,
        }).then(res => res.json()).then(res => {
            if (res.success) {
                setDisplayImg(res.data.img);
                setName(res.data.name);
                setContactNo(res.data.contact_no);
                setEmail(res.data.email);
                if (res.data.notifications.length > 0) {
                    setNotificationList(res.data.notifications)
                }
                if (res.data.need_to_add_service && helperMode) {
                    setNotification('You need to add your services on the My services page.')
                }
                if (res.data.has_live_help) {

                    document.getElementById('history-btn').classList.add('history-btn-badge');
                    setHasLiveHelp(true);
                }


            } else {
                if (!window.location.href.includes('profile')) {
                    window.location.href = '/profile';
                }
            }
        })
        let item = 'home-btn';
        if (window.location.href.includes('help')) {
            item = 'history-btn';
        } else if (window.location.href.includes('create')) {
            item = 'create-btn';
        }
        document.getElementById(item).classList.add('active-btn');
        if (helperMode) {
            setInterval(function () {
                fetch(props.url + 'user/update-location/', {
                    method: 'POST',
                    headers: headers,
                    body: JSON.stringify({
                        // TODO: get location from client side and update it below
                        'latitude': 12.02324,
                        'longitude': 43.23324
                    })
                }).then(res => res.json()).then(res => {
                    if (!res.data) {
                        console.log(res.message)
                    }
                })
            }, 60000);
        }
    }, []);
    const routes = {
        '/': () => <Landing hasLiveHelp={hasLiveHelp} setNotification={setNotification} setPageTitle={setPageTitle}
            url={props.url} cookies={props.cookies} customAlert={customAlert} helperMode={helperMode}
            headers={headers} name={name}/>,
        '/create': () => <Create setShowMainSpinner={setShowMainSpinner} setNotification={setNotification}
            setPageTitle={setPageTitle} url={props.url} cookies={props.cookies}
            customAlert={customAlert}
            headers={headers} />,
        '/profile': () => <Profile setNotification={setNotification} helperMode={helperMode}
            setPageTitle={setPageTitle} url={props.url} cookies={props.cookies}
            customAlert={customAlert} setShowMainSpinner={setShowMainSpinner}
            headers={headers} />,
        '/be-a-helper': () => <BeAHelper setShowMainSpinner={setShowMainSpinner} setNotification={setNotification}
            setPageTitle={setPageTitle} url={props.url} cookies={props.cookies}
            customAlert={customAlert}
            headers={headers} />,
        '/help': () => <History setNotification={setNotification} helperMode={helperMode}
            setPageTitle={setPageTitle} url={props.url} cookies={props.cookies}
            customAlert={customAlert}
            headers={headers} />,
        '/helper-service': () => <HelperService setNotification={setNotification} helperMode={helperMode}
            setPageTitle={setPageTitle} url={props.url} cookies={props.cookies}
            customAlert={customAlert}
            headers={headers} />,
        '/wallet': () => <Wallet setNotification={setNotification} setPageTitle={setPageTitle} url={props.url}
            cookies={props.cookies} customAlert={customAlert} headers={headers} />,
        '/my-review': () => <Review setNotification={setNotification} setPageTitle={setPageTitle} url={props.url}
            cookies={props.cookies} customAlert={customAlert} headers={headers} />,
        '/payment/:id': () => <Payment setNotification={setNotification} setPageTitle={setPageTitle} url={props.url}
            cookies={props.cookies} customAlert={customAlert} headers={headers} />,
        '/payment-success': () => <DisplayMessage cookies={props.cookies} content={'pmt-success'} />,
        '/payment-cancel': () => <DisplayMessage cookies={props.cookies} content={'pmt-cancel'} />,

        '/support': () => <Support setNotification={setNotification} setPageTitle={setPageTitle} url={props.url}
            cookies={props.cookies} headers={headers} customAlert={customAlert} />,
        '/faq': () => <FAQ />,

    };

    const routeResults = useRoutes(routes);

    return <React.Fragment>
        <br />
        <div className="page-header" id={"script-trigger"}>
            <div className="header-wrapper container row d-flex align-items-center nav-menus"
                style={{ 'height': '60px' }}>
                <div className="col">
                    {showMainSpinner === true ? (
                        <div className="spinner-border spinner-border-sm text-dark" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    ) : (
                        <img src="assets/img/logo.png" alt="logo" className="img-fluid rounded-circle shadow-sm"
                            style={{ width: '2rem' }} onClick={() => window.location.href = '/'} />
                    )}
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>{pageTitle}
                </div>
                <div className="nav-right right-header col">
                    <ul className="nav-menus">
                        <li>
                            <div className="notification-box text-dark" id={"notification-bell"}>
                                <div>
                                    <span><i className="fa fa-bell" style={{ color: '#777680' }}></i></span>
                                </div>
                                {notificationList &&
                                    <span className="badge rounded-pill badge-secondary">&nbsp;</span>}
                            </div>
                        </li>
                        <li className="profile-nav onhover-dropdown text-right pe-0 py-0">
                            <div className="media profile-media">
                                <img className="rounded-circle"
                                    style={{ 'width': '2rem', 'height': '2rem', 'borderRadius': '50%' }}
                                    src={displayImg ? displayImg : 'assets/img/user.png'} alt="" />
                                <div className="media-body">
                                    <span>{name}</span>
                                    <p className="mb-0">{contactNo}
                                        <i className="middle fa fa-angle-down"></i></p>
                                </div>
                            </div>
                            <ul className="profile-dropdown onhover-show-div">
                                <li>
                                    <a href="/profile">
                                        <i data-feather="user"></i>
                                        <span>Account </span>
                                    </a>
                                </li>
                                {helperMode && <React.Fragment>
                                    <li>
                                        <a href="/helper-service">
                                            <i data-feather="user"></i>
                                            <span>My services</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/wallet">
                                            <i data-feather="user"></i>
                                            <span>My wallet</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/my-review">
                                            <i data-feather="user"></i>
                                            <span>My reviews</span>
                                        </a>
                                    </li>
                                </React.Fragment>}
                                <li>
                                    <a href="/support">
                                        <i data-feather="user"></i>
                                        <span>Contact support</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="/faq">
                                        <i data-feather="user"></i>
                                        <span>Frequently Asked Questions</span>
                                    </a>
                                </li>
                                <li><a href="#" onClick={() => logOut()}><i
                                    data-feather="log-in"> </i><span>Log out</span></a></li>
                            </ul>
                        </li>
                    </ul>
                </div>
                {/*    <div className="onhover-show-div notification-dropdown">*/}
                {/*        <h6 className="f-18 mb-0 dropdown-title">Notifications </h6>*/}
                {/*        <ul>*/}
                {/*            {notificationList ? notificationList.map(item => {*/}
                {/*                return <div key={item.id}*/}
                {/*                            className="alert alert-secondary alert-dismissible"*/}
                {/*                            role="alert">*/}
                {/*                    <small>{item.created_at.replace('T', ' ')}</small> - {item.content}*/}
                {/*                    <button type="button" className="btn-close" data-bs-dismiss="alert"*/}
                {/*                            onClick={() => deleteNotification(item.id)}*/}
                {/*                            aria-label="Close"></button>*/}
                {/*                </div>*/}
                {/*            }) : <NothingNew/>}*/}
                {/*        </ul>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        </div>
        <div className="page-body-wrapper bg-light" style={{ 'backgroundColor': 'white' }}>
            <div className="page-body">
                <div className="container-fluid" style={{ 'width': '93%', 'marginTop': '3rem' }}>
                    <MainContext.Provider value={contextVars}>
                        {routeResults}
                    </MainContext.Provider>
                </div>
            </div>
        </div>


        {
            notification &&
            <div className="card-body toast-rtl bottom-0 start-50 translate-middle-x"
                style={{ 'marginBottom': '4rem', 'zIndex': '999', 'position': 'fixed' }}>
                <div
                    className="toast default-show-toast align-items-center text-light bg-info border-0 fade show"
                    role="alert" aria-live="assertive" aria-atomic="true">
                    <div className="d-flex justify-content-between">
                        <div className="toast-body">{notification}</div>
                        <button className="btn-close btn-close-white me-2 m-auto" type="button"
                            onClick={() => setNotification(null)} data-bs-dismiss="toast"
                            aria-label="Close"></button>
                    </div>
                </div>
            </div>
        }
        <br />
        <br />
        <br />
        <br />
        {
            helperMode && <React.Fragment>
                <span className="bg-success text-center" style={{
                    'position': 'fixed',
                    'bottom': '4rem',
                    'left': 0,
                    'zIndex': '100',
                    'width': '100%',
                }}>Helper mode [ON]</span>

            </React.Fragment>
        }
        <nav style={{
            'position': 'fixed',
            'bottom': '0',
            'backgroundColor': 'white',
            color: 'grey',
            'width': '100%',
            'height': '3.7rem',
            'overflow': 'hidden'
        }} className="shadow-lg navbar bg-navbar-theme nav d-flex align-items-center justify-content-around">

            <a href="/" id="home-btn">
                <i className="fa fa-home bottom-nav-btn"></i>
            </a>
            {props.cookies.get('helperMode') !== 'true' &&
                <a href="/create" id="create-btn">
                    <i className="fa fa-plus-square-o bottom-nav-btn"></i>
                </a>
            }
            <a href="/help" id="history-btn">
                <i className="fa fa-list-alt bottom-nav-btn"></i>
            </a>
            <div className="form-check bottom-nav-btn form-switch" id="helper-mode-switch"
                data-toggle="tooltip" data-placement="top" title="Switch to helper mode">
                <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault"
                    onClick={() => toggleOnHelperMode()}
                    defaultChecked={helperMode} />
                <label className="form-check-label" htmlFor="flexSwitchCheckDefault"></label>
            </div>
        </nav>


    </React.Fragment>;
}

