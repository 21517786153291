import React from "react";
import Stars from "./Stars";

export default function Helper(props) {
    if (props.helper) {
        return <React.Fragment>
            <div className="mb-4">
                <div className="align-items-center justify-content-center">
                    <div className="d-flex align-items-center justify-content-center align-items-sm-center gap-4">
                        <img
                            src={props.baseUrl + props.helper.display_pic}
                            alt="user-avatar"
                            className="d-block rounded"
                            height="100"
                            width="100"
                        />

                    </div>
                    <center>
                        <br />
                        <h5>{props.helper.helper_name}</h5>
                        <Stars count={props.helper.helper_stars.stars__avg} displayOnly={true} />
                        <br />
                        {props.assignHelper &&
                            <button type="button" className="btn btn-primary" onClick={() => props.assignHelper(props.helper.helper_queue_id)}>Assign</button>}
                    </center>
                </div>
                <div className="divider divider-primary">
                    <div className="divider-text">About</div>
                </div>
                <div className="border border-primary" style={{ "backgroundColor": "#f8f9fa" }}>
                    <div className="mb-5">
                        <div className="p-4">
                            <p className="font-italic mb-1">Name : {props.helper.helper_name}</p>
                            <p className="font-italic mb-0">Education : {props.helper.helper_education_level}</p>
                            <div className="divider divider-primary">
                                <div className="divider-text">Proposed</div>
                            </div>
                            <p className="font-italic mb-1">Amount : <i className="bx bx-rupee"></i> <strong>{props.helper.proposed_amount}</strong></p>
                            <p className="font-italic mb-1">Time : {props.helper.proposed_at ? props.helper.proposed_at.replace('T', ' ') : "(not specified)"}</p>
                        </div>
                    </div>
                </div>
                <br />
                <div className="divider divider-success">
                    <div className="divider-text">Reviews</div>
                </div>
                <div className="row">
                    {props.helper.helper_reviews.map(item => {
                        return <React.Fragment key={item.id}>
                            <div className="border">
                                <div className="p-3">

                                    <img src="https://image.ibb.co/jw55Ex/def_face.jpg" style={{ 'width': '4rem', 'marginTop': '1rem' }}
                                        className="img img-rounded" alt="User" />
                                    <br />
                                    <strong>{item.created_by}</strong>
                                    <Stars count={item.stars} />

                                    <div className="clearfix"></div>
                                    <p>{item.comment}
                                    </p>
                                </div>
                            </div>
                            <div>
                                &nbsp;
                            </div>
                        </React.Fragment>
                    })}
                </div>
            </div>
        </React.Fragment >
    } else {
        return " "
    }
}