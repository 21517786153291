import React, {Component} from "react";
import axios from "axios";
import Address from '../utils/Address';
import UploadImage from '../utils/UploadImage';
import DateTime from "../utils/DateTime";
import CreateDetail from "../utils/CreateDetail";

export default function Create(props) {
    let [images, setImages] = React.useState([]);
    let [services, setServices] = React.useState([]);
    let [addressId, setAddressId] = React.useState(null);
    let [description, setDescription] = React.useState(null);
    let [budgetAmount, setBudgetAmount] = React.useState(null);
    let [scheduledAt, setScheduledAt] = React.useState(null);
    let [scheduledTime, setScheduledTime] = React.useState(null);
    let [scheduledDate, setScheduledDate] = React.useState(null);
    let [selectedService, setSelectedService] = React.useState(null);
    let [uploadImg, setUploadImg] = React.useState([]);
    let [fieldValues, setFieldValues] = React.useState({});
    let [step, setStep] = React.useState(1);
    let [formFields, setFormFields] = React.useState(null);


    function nextStep() {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        switch (step) {
            case 1: {
                let data = {};
                if (formFields) {
                    for (const i in formFields) {
                        data[formFields[i].id] = document.getElementById(formFields[i].id).value;
                    }
                }
                setFieldValues(data);
                let amt = document.getElementById('budget-amount').value;
                if (amt === '') {
                    amt = 0;
                }
                setBudgetAmount(amt);
                setDescription(document.getElementById('description').value)
                setStep(step + 1);
            }
                break;
            case 2:
                let dateString = scheduledDate.replace('\n', ' ') + ' ' + scheduledTime;
                let formattedDateString = new Date(dateString)
                setScheduledAt(formattedDateString.toISOString().slice(0, 16).replace('T', ' '));
                setStep(step + 1);
                break;
            default:
                alert('Something went wrong!');
                break;
        }

    }

    let setSelectedServiceFn = (service) => {
        setSelectedService(service)
        fetch(props.url + 'task/create/' + service.id, {
            method: 'GET',
            headers: props.headers
        }).then(res => res.json()).then(res => {
            if (res.data) {
                setFormFields(
                    res.data.fields
                );
            } else {
                props.setNotification(res.message || 'Something went wrong!');
            }
        });
    }
    let handleInputChange = (event) => {
        event.preventDefault();
        let imgs = images.concat([event.target.files[0]])
        setImages(imgs)

    };
    let addNewImg = () => {
        let d = new Date();
        let r = Math.random().toString() + Math.random().toString();
        let idKey = d.getTime() + '-' + r;
        setUploadImg([<UploadImage key={idKey} idKey={idKey}
                                   handleInputChange={handleInputChange}/>].concat(uploadImg))
    }
    let createHelp = (event) => {
        event.preventDefault();
        let formData = new FormData();
        if (addressId) {
            props.setShowMainSpinner(true);
            formData.append('address_id', addressId);
            formData.append('service_id', selectedService.id);
            formData.append('description', description);
            formData.append('budget_amount', budgetAmount);
            formData.append('scheduled_at', scheduledAt);
            let fields = [];
            for (const [key, value] of Object.entries(fieldValues)) {
                fields.push(JSON.stringify({
                    'field_id': key,
                    'value': value
                }));
            }
            formData.append('service_fields', fields);
            for (let i = 0; i < images.length; i++) {
                formData.append('images', images[i], images[i].name);
            }
            axios.post(props.url + 'task/create/',
                formData, {
                    headers: {
                        'content-type': 'multipart/form-data',
                        Authorization: 'Token ' + props.cookies.get('user')['token']
                    }
                }).then(res => {
                if (res.data.error) {
                    props.setNotification(res.data.message || 'Something went wrong!');
                } else {
                    props.setNotification(res.data.message);
                    setTimeout(function () {
                        window.location.href = '/help';
                    }, 2000);
                }
            }).then(() => props.setShowMainSpinner(false))
        } else {
            props.setNotification('Address not selected!')
        }
    }

    let steps = {
            1: <React.Fragment>
                <h5 className="card-title">Describe your help request</h5>
                <br/>
                <div className="form-floating mb-3">
                    <div className="list-group" id="service-id">
                        {services && services.map(item => {
                            if (selectedService) {
                                if (selectedService.id === item.id) {
                                    return <a href="#" key={item.id} className="list-group-item list-group-item-action active" aria-current="true">
                                        {item.name}
                                    </a>
                                }
                            }
                            return <a href="#!" key={item.id} onClick={() => setSelectedServiceFn(item)}
                                      className="list-group-item list-group-item-action">
                                {item.name}
                            </a>
                        })}
                    </div>
                </div>
                <br/>
            </React.Fragment>,
            2: <React.Fragment>
                <CreateDetail formFields={formFields}/>
                <br/>

                <div className="mb-3 input-group">
                    <span className="input-group-text"><i className="fa fa-rupee"></i></span>
                    <input
                        type="number" id="budget-amount"
                        className="form-control" onInput={(event) => {
                        if (event.target.value.length > 7) {
                            event.target.value = event.target.value.slice(0, 4);
                        }
                    }}
                        defaultValue={budgetAmount}
                        placeholder="Your budget (optional)"/>
                    <span className="input-group-text" aria-disabled={true}>.00</span>
                </div>
                <br/>
                <div className="form-floating mb-3">
                                <textarea className="form-control" placeholder="description"
                                          defaultValue={description}
                                          id="description" style={{"height": "100px"}}></textarea>
                    <label htmlFor="floatingTextarea">Explain in brief about the help...</label>
                </div>
            </React.Fragment>,
            3:
                <React.Fragment>
                    <DateTime setScheduledTime={setScheduledTime} setScheduledDate={setScheduledDate}/>
                    <br/>
                </React.Fragment>,
            4:
                <React.Fragment>
                    <h5 className="card-title">Add images(optional) and address</h5>
                    <br/>
                    <div className="form-floating mb-3">
                        <button type="button" onClick={() => addNewImg()} className="btn btn-sm border">
                            <span><i className="bx bx-image-add"></i>Add images</span>
                        </button>
                        <br/>
                        <br/>
                        {uploadImg}
                    </div>
                    <br/>
                    <Address setShowMainSpinner={props.setShowMainSpinner} url={props.url}
                             customAlert={props.customAlert}
                             setAddressId={setAddressId} headers={props.headers}/>
                    <br/>

                </React.Fragment>,
        }
    ;

    React.useEffect(() => {
        props.setNotification('');
        props.setShowMainSpinner(true);
        props.setPageTitle('Create new task');
        fetch(props.url + 'service/', {
            method: 'GET',
            headers: props.headers
        }).then(res => res.json()).then(res => {
            if (res.data) {
                setServices(res.data)

            } else {
                props.setNotification(res.message || 'Something went wrong!');
            }
        }).then(() => props.setShowMainSpinner(false))
    }, [])


    return <React.Fragment>
        <form id="create-form" onSubmit={(event) => createHelp(event)}>
            {steps[step]}
            <div className={"row d-flex justify-content-between"}>
                {step !== 3 && step!==1 ? <button type="button" onClick={() => nextStep()}
                                      className="btn btn-md btn-primary" style={{'width': '48%'}}>
                    <span><i className="bx bx-skip-next"></i> &nbsp;&nbsp;&nbsp;Next</span>
                </button> : step === 3 && <button type="submit"
                                    className="btn btn-md btn-primary" style={{'width': '48%'}}>
                    <span><i className="bi bi-telegram"></i> &nbsp;&nbsp;&nbsp;Post task</span>
                </button>}
                <button type="button" onClick={() => setStep(step - 1)}
                        className="btn btn-md btn-outline-danger" style={{'width': '48%'}}>
                    <span>&nbsp;&nbsp;&nbsp;Back</span>
                </button>
            </div>

        </form>
    </React.Fragment>

}
