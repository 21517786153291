import React from "react";

export default function Stars(props) {
    let count = props.count;
    if(count===null){
        count = 0;
    }
    function rate(idOfClick) {
        for (let index = idOfClick; index >= 0; index--) {
            document.getElementById("star-" + index).classList = 'text-warning';
        }
        for (let index = idOfClick+1; index < 5; index++) {
            document.getElementById("star-" + index).classList = 'text-light';
        }
        document.getElementById('rating').value = idOfClick+1;
    }
    if (count||props.displayOnly) {
        return <div>
            {Array(5)
                .fill(0)
                .map((_, idx) => (
                    <span key={idx} className={idx < count ? "text-warning" : "text-light"}><i
                        className="bx bxs-star"></i> </span>
                ))}
        </div>
    }
    else {

        return <div>
            <input type={"hidden"} id="rating" value="" />
            {Array(5)
                .fill(0)
                .map((_, idx) => (
                    <span key={idx} className="text-light" id={"star-" + idx} onClick={() => rate(idx)} style={{'transform':'scale(1.5)'}}><i
                        className="bx bxs-star"></i> </span>
                ))}
        </div>
    }

}