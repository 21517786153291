import React, { Component } from "react";
import axios from "axios";
import Spinner from "../utils/Spinner";

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            displayImg: null,
            obj: null,
            genders: [],
            eduLevels: [],
        };
    }

    displayImgChange = (event) => {
        event.preventDefault();
        this.setState({
            displayImg: event.target.files[0]
        })
    };
    submitForm = (event) => {
        event.preventDefault();
        this.props.setShowMainSpinner(true);
        let reqBody = new FormData();
        reqBody.append('first_name', document.getElementById('first-name').value);
        reqBody.append('last_name', document.getElementById('last-name').value);
        reqBody.append('email', document.getElementById('email').value);
        reqBody.append('date_of_birth', document.getElementById('date-of-birth').value);
        reqBody.append('gender', document.getElementById('gender').value);
        if (this.state.displayImg) {
            reqBody.append('img', this.state.displayImg, this.state.displayImg.name);
        }
        if (this.props.helperMode) {
            reqBody.append('edu_level', document.getElementById('edu-level').value);
        }
        axios.post(this.props.url + 'user/profile/',
            reqBody, {
            headers: {
                'content-type': 'multipart/form-data',
                Authorization: 'Token ' + this.props.cookies.get('user')['token']
            }
        }).then(res => {
            if (res.data.error) {
                this.props.setNotification(res.data.message || 'Something went wrong!');
            } else {
                this.props.setNotification(res.data.message);
            }
            this.props.setShowMainSpinner(false);
            setTimeout(function () {
                window.location.href = '/';
            }, 2000);
        })
    }

    componentDidMount() {
        this.props.setPageTitle('My profile');
        if (this.props.helperMode) {
            fetch(this.props.url + 'education-level/', {
                method: 'GET',
                headers: this.props.headers
            }).then(res => res.json()).then(res => {
                if (res.data) {
                    this.setState({
                        eduLevels: res.data
                    });

                } else if (!res.under_review) {
                    this.props.setNotification(res.message || 'Something went wrong!');
                }
            })
        }
        fetch(this.props.url + 'user/profile/', {
            method: 'GET',
            headers: this.props.headers,
        }).then(res => res.json()).then(res => {
            if (res.data) {
                this.setState({
                    obj: res.data
                });
            } else {
                if (!window.location.href.includes('profile')) {
                    window.location.href = '/profile';
                }
            }
        })
    }

    render() {
        return <React.Fragment>{!this.state.obj ? <Spinner /> :
            <React.Fragment>
                <div className="card-header d-flex justify-content-between align-items-center">
                    <h5 className="mb-0">Update your profile</h5>
                </div>
                <br/>
                <div className="card-body">
                    <form onSubmit={(event) => this.submitForm(event)}>
                        <div className="mb-3">
                            <label className="form-label" htmlFor="basic-icon-default-fullname">First Name</label>
                            <div className="input-group input-group-merge  shadow-sm">
                                <span id="basic-icon-default-fullname2" className="input-group-text"><i
                                    className="fa fa-user"></i></span>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="first-name"
                                    placeholder="John"
                                    defaultValue={this.state.obj.first_name}
                                    required
                                />
                            </div>
                        </div>
                        <div className="mb-3">
                            <label className="form-label" htmlFor="basic-icon-default-company">Last Name</label>
                            <div className="input-group input-group-merge  shadow-sm">
                                <span id="basic-icon-default-company2" className="input-group-text"
                                ><i className="fa fa-user"></i
                                ></span>
                                <input
                                    type="text"
                                    id="last-name"
                                    className="form-control"
                                    placeholder="Doe"
                                    defaultValue={this.state.obj.last_name}
                                    required
                                />
                            </div>
                        </div>
                        <div className="mb-3">
                            <label className="form-label" htmlFor="basic-icon-default-company">Date of Birth</label>
                            <div className="input-group input-group-merge  shadow-sm">
                                <span id="basic-icon-default-company2" className="input-group-text"
                                ><i className="fa fa-calendar"></i
                                ></span>
                                <input
                                    type="date"
                                    id="date-of-birth"
                                    className="form-control"
                                    placeholder="Date of birth"
                                    defaultValue={this.state.obj.date_of_birth ? this.state.obj.date_of_birth.split('T')[0] : ''}
                                    required
                                />
                            </div>
                        </div>
                        <div className="mb-3">
                            <label className="form-label" htmlFor="basic-icon-default-company">Email Id</label>
                            <div className="input-group input-group-merge  shadow-sm">
                                <span id="basic-icon-default-company2" className="input-group-text"
                                ><i className="fa fa-envelope"></i
                                ></span>
                                <input
                                    type="email"
                                    id="email"
                                    className="form-control"
                                    placeholder="example@contoso.com"
                                    defaultValue={this.state.obj.email}
                                    required
                                />
                            </div>
                        </div>
                        <div className="mb-3">
                            <label className="form-label" htmlFor="basic-icon-default-company">Contact number</label>
                            <div className="input-group input-group-merge  shadow-sm">
                                <span id="basic-icon-default-company2" className="input-group-text"
                                ><i className="fa fa-phone"></i
                                ></span>
                                <input
                                    type="text"
                                    className="form-control"
                                    defaultValue={'   ' + this.state.obj.contact_no}
                                    disabled
                                />
                            </div>
                        </div>
                        {this.props.helperMode && <div className="mb-3">
                            <label className="form-label">Education level</label>
                            <select className="form-select" id="edu-level">
                                {this.state.obj.edulevel === undefined &&
                                    <option value={''} defaultValue={true}>choose level</option>
                                }
                                {this.state.eduLevels && this.state.eduLevels.map(item => {
                                    let selected = false;
                                    if (this.state.obj) {
                                        if (this.state.obj.edu_level === item.id) {
                                            selected = true;
                                        }
                                    }
                                    return <option key={item.id} defaultValue={selected}
                                        value={item.id}>{item.title}</option>;
                                })}
                            </select>
                        </div>}
                        <div className="mb-3">
                            <label className="form-label">Gender</label>
                            <select className="form-select" id="gender" defaultValue={this.state.obj.gender}>
                                <option>Choose gender</option>
                                ;
                                <option value='m'>Male</option>;
                                <option value='f'>Female</option>;
                                <option value='o'>Other</option>;

                            </select>
                        </div>
                        <div className="mb-3">
                            <label className="form-label" htmlFor="basic-icon-default-message">Display image </label>
                            <br />
                            <small>Leave blank if no change</small>
                            <div className="input-group input-group-merge  shadow-sm">
                                <span id="basic-icon-default-message2" className="input-group-text"><i
                                    className="fa fa-image"></i></span>
                                <input type="file" name="images" className="form-control upload-image"
                                    placeholder="Image"
                                    id="display-img"
                                    onChange={(event) => this.displayImgChange(event)} />

                            </div>
                        </div>
                        <div className="mb-3">
                            <input type="checkbox" id="terms" required />
                            <label className="form-check-label" htmlFor="terms">
                                By clicking this checkbox, I agree to the <a target="_blank"
                                    href={this.props.url.slice(0, -4) + 'terms-and-conditions'} rel="noreferrer">terms
                                    & conditions.</a>
                            </label>
                            <div className="rectangle-box">
                                Please read the terms and conditions
                            </div>
                        </div>
                        <div className="row d-flex align-items-center justify-content-center">
                            <button type="submit" className="btn btn-sm btn-primary">Update profile</button>
                        </div>
                    </form>
                </div>
            </React.Fragment>
        }</React.Fragment>
    }
}

export default Profile;