import React from "react";

export default function CreateDetail(props) {

    React.useEffect(() => {
        console.log(props.formFields)
    }, []);
    return <React.Fragment>
        {props.formFields && props.formFields.map(field => {
            return <div className="mb-3 input-group" key={field.name}>
                <span className="input-group-text">{field.name}</span>
                <input
                    type="number" id={field.id}
                    className="form-control"
                    defaultValue={field.default_value}/>
            </div>
        })}
    </React.Fragment>
}