import React from "react";

export default function Address(props) {
    let [countries, setCountries] = React.useState([]);
    let [addresses, setAddresses] = React.useState([]);
    let [showMapBox, setShowMapBox] = React.useState(false);
    let [states, setStates] = React.useState([]);

    function fetchLocalities(event) {
        if (event.target.value.length === 6) {
            if (!showMapBox) {
                setShowMapBox(true);
                let script1 = document.createElement('script');
                script1.type = 'text/javascript';
                script1.src = "/assets/js/google-map.js";
                document.body.appendChild(script1);
            }
            fetch('https://api.postalpincode.in/pincode/' + event.target.value, {
                method: 'GET',
            }).then(res => res.json()).then(res => {
                if (res) {
                    for (let i = 0; i < document.getElementById('state').options.length; i++) {
                        const option = document.getElementById('state').options[i];
                        if (option.textContent === res[0].PostOffice[0].State) {
                            option.selected = true;
                        }
                    }
                    document.getElementById('city').value = res[0].PostOffice[0].District;
                    document.getElementById('locality').value = res[0].PostOffice[0].Name;
                } else {
                    props.customAlert(res.message || 'Something went wrong!');
                }
            }).then(() => props.setShowMainSpinner(false))
        }
    }

    function fetchStates(countryId) {
        props.setShowMainSpinner(true);
        fetch(props.url + 'state/' + countryId, {
            method: 'GET',
            headers: props.headers
        }).then(res => res.json()).then(res => {
            if (res.data) {
                setStates(res.data);
            } else {
                props.customAlert(res.message || 'Something went wrong!');
            }
        }).then(() => props.setShowMainSpinner(false))
    }

    function clearAddressForm() {
        document.getElementById('line-1').value = '';
        document.getElementById('line-2').value = '';
        document.getElementById('locality').value = '';
        document.getElementById('postal-code').value = '';
        document.getElementById('city').value = '';
        document.getElementById('state').value = '';
        document.getElementById('country').value = '';
        document.getElementById('latitude').value = '';
        document.getElementById('longitude').value = '';
    }

    function fetchAddresses() {
        props.setShowMainSpinner(true);
        fetch(props.url + 'user/address/', {
            method: 'GET',
            headers: props.headers
        }).then(res => res.json()).then(res => {
            if (res.data) {
                setAddresses(res.data);
            } else {
                props.customAlert(res.message || 'Something went wrong!');
            }
        }).then(() => props.setShowMainSpinner(false))
    }

    function createAddress() {
        document.getElementById('address-loader').style.display = '';
        let payload = {
            'line_1': document.getElementById('line-1').value,
            'line_2': document.getElementById('line-2').value,
            'locality': document.getElementById('locality').value,
            'postal_code': document.getElementById('postal-code').value,
            'city': document.getElementById('city').value,
            'state': document.getElementById('state').value,
            'country': document.getElementById('country').value,
            'latitude': document.getElementById('latitude').value,
            'longitude': document.getElementById('longitude').value
        }
        let proceed = true;
        for (let prop in payload) {
            if (Object.prototype.hasOwnProperty.call(payload, prop)) {
                if (!payload[prop]) {
                    props.customAlert(prop + ' cannot be empty!')
                    proceed = false;
                    break;
                }
            }
        }
        if (proceed) {
            props.setShowMainSpinner(true);
            fetch(props.url + 'user/address/', {
                method: 'POST',
                headers: props.headers,
                body: JSON.stringify(payload)
            }).then(res => res.json()).then(res => {
                if (res.data) {
                    fetchAddresses();
                    clearAddressForm();
                    document.getElementById('address-loader').style.display = 'none';
                    window.location.href = '#saved-addresses';
                } else {
                    props.customAlert(res.message || 'Something went wrong!');
                }
            }).then(() => {
                props.setShowMainSpinner(false)
                document.getElementById('create-address-form').style.display = 'none'
            })
        }
    }

    React.useEffect(() => {
        // props.setShowMainSpinner(true);
        // fetch(props.url + 'country/', {
        //     method: 'GET',
        //     headers: props.headers
        // }).then(res => res.json()).then(res => {
        //     if (res.data) {
        //         setCountries(res.data);
        //     } else {
        //         props.customAlert(res.message || 'Something went wrong!');
        //     }
        // }).then(() => props.setShowMainSpinner(false))
        fetchStates(1);
        fetchAddresses();
    }, []);
    return <React.Fragment>
        <div className="row mb-3">
            <legend className="col-form-label col-sm-2 pt-0" id="saved-addresses">Saved addresses</legend>
            <div className="list-group">
                {addresses ? addresses.map(item => {
                    return <a className="list-group-item list-group-item-action list-hover-primary" href="#"
                              key={item.id}>
                        <i className={"fa fa-home"}/>
                        <input className="form-check-input" type="radio" id={item.id} name="address"
                               onClick={() => props.setAddressId(item.id)}/>
                        &nbsp;&nbsp;&nbsp;
                        <label className="form-check-label" htmlFor={item.id}>
                            {item.get_name}
                        </label>

                    </a>
                }) : <small><strong>No saved addresses found!</strong></small>}
            </div>
        </div>
        <center>
            <div className="divider">
                <div className="divider-text">Or</div>
            </div>
            <button type="button" className="btn btn-outline-primary btn-sm"
                    onClick={() => document.getElementById('create-address-form').style.display = ''}>Create new?
            </button>
        </center>
        <div className="card" id="create-address-form" style={{'display': 'none'}}>
            <div className="card-body" id="create-form">
                <h5 className="card-title">Create a new address</h5>

                <div className="form-floating mb-3">
                    <select className="form-select" id="country" name="country"
                            aria-label="Countries" onChange={(event) => {
                        fetchStates(event.target.value)
                    }} disabled>

                        <option value={1}>India</option>
                        <option defaultValue={true}>choose country</option>
                        {countries && countries.map(item => {
                            return <option key={item.id} value={item.id}>{item.name}</option>;
                        })}
                    </select>
                </div>
                <div className="form-floating mb-3">
                    <select className="form-select" id="state" name="state"
                            aria-label="States">
                        <option defaultValue={true}>choose state</option>
                        {states && states.map(item => {
                            return <option key={item.id} value={item.id}>{item.name}</option>;
                        })}
                    </select>
                </div>

                <div className="form-floating mb-3">
                    <input type="text" className="form-control" name="postal-code" id="postal-code"
                           placeholder="Postal code" onChange={(event) => fetchLocalities(event)}/>
                    <label htmlFor="floatingInput">Postal code</label>
                </div>

                <div className="form-floating mb-3">
                    <input type="text" className="form-control" name="city" id="city"
                           placeholder="city"/>
                    <label htmlFor="floatingInput">City</label>
                </div>
                <div className="form-floating mb-3">
                    <input type="text" className="form-control" name="locality" id="locality"
                           placeholder="Locality"/>
                    <label htmlFor="floatingInput">Locality</label>
                </div>
                <div className="form-floating mb-3">
                    <input type="text" className="form-control" name="line-1" id="line-1"
                           placeholder="Line 1"/>
                    <label htmlFor="floatingInput">Line 1</label>
                </div>
                <div className="form-floating mb-3">
                    <input type="text" className="form-control" name="line-2" id="line-2"
                           placeholder="Line 2"/>
                    <label htmlFor="floatingInput">Line 2</label>
                </div>

                {showMapBox && <div id="mapbox">
                    <div className="form-floating mb-3">
                        <input type="text" className="form-control" id="location-search"
                               placeholder="Search..."/>
                        <label htmlFor="floatingInput">Search...</label>
                    </div>
                    <div id="map"></div>
                    <input type='hidden' name="latitude" id="latitude" value=''/>
                    <input type='hidden' name="longitude" id="longitude" value=''/>
                </div>}


                <center>
                    <div style={{'display': 'none'}} id="address-loader"
                         className="spinner-border" role="status">
                    </div>
                    <br/>
                    <button type="button" onClick={() => createAddress()} className="btn btn-md btn-outline-primary">
                        <span><i className="bi bi-plus"></i> &nbsp;&nbsp;&nbsp;Create Address</span>
                    </button>
                </center>
            </div>
        </div>

    </React.Fragment>
}