import React from "react";
import Stars from "../utils/Stars";
import Helper from "../utils/Helper";

export default function Queue(props) {
    let [helpers, setHelpers] = React.useState([]);
    let obj = props.help;
    let [selectedHelper, setSelectedHelper] = React.useState(null);
    let sortQueue = (item) => {
        let sortedHelpers = [];
        if (item === 'rating') {
            sortedHelpers = [].concat(helpers)
                .sort((a, b) => b.helper_stars.stars__avg - a.helper_stars.stars__avg);
        } else if (item === 'price') {
            sortedHelpers = [].concat(helpers)
                .sort((a, b) => a.proposed_amount - b.proposed_amount);
        }
        setHelpers(sortedHelpers);
    }
    let assignHelper = (qId) => {
        document.getElementById('close-helper-modal').click();
        props.setShowMainSpinner(true);
        fetch(props.url + 'task/help-requests/', {
            method: 'POST',
            headers: props.headers,
            body: JSON.stringify({
                'helper_queue_id': qId
            })
        }).then(res => res.json()).then(res => {
            if (res.data) {
                props.setNotification(res.message);
                setTimeout(function () {
                    window.location.href = '/help';
                }, 3000);

            } else {
                props.setNotification(res.message || 'Something went wrong!');
            }
        }).then(() => props.setShowMainSpinner(false));
    }
    const MINUTE_MS = 20000;
    let baseUrl = props.url;

    function cancelHelp() {
        let r = window.confirm('Are you sure?');
        if (r) {
            fetch(props.url + 'task/cancel/' + obj.id + '/', {
                method: 'GET',
                headers: props.headers,
            }).then(res => res.json()).then(res => {
                if (res.data) {
                    props.setNotification(res.message);
                    setTimeout(function () {
                        window.location.href = '/help';
                    }, 2000);
                } else {
                    props.setNotification(res.message || 'Something went wrong!');
                }
            })
        }
    }

    function fetchQueue() {
        fetch(props.url + 'task/helper-queue/' + obj.id, {
            method: 'GET',
            headers: props.headers
        }).then(res => res.json()).then(res => {
            if (res.data) {
                setHelpers(res.data);
            } else {
                props.setNotification(res.message || 'Something went wrong!');
            }
        })
    }

    React.useEffect(() => {
        fetchQueue();
        const interval = setInterval(() => {
            fetchQueue();
        }, MINUTE_MS);
        props.setPageTitle('Searching...');
        return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }, []);
    return <React.Fragment>

        <div className="col-md-6 col-lg-4 order-2 mb-4">
            {helpers.length > 0 &&
                <div className="card h-100">
                    <div className="card-header d-flex align-items-center justify-content-between">
                        <h5 className="card-title m-0 me-2">Helpers</h5>
                        <div className="dropdown">
                            <button
                                className="btn p-0"
                                type="button"
                                id="transactionID"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false">
                                <i className="bx bx-dots-vertical-rounded"></i>
                            </button>
                            <div className="dropdown-menu dropdown-menu-end" aria-labelledby="transactionID">
                                <a className="dropdown-item" href="#" onClick={() => sortQueue('price')}>Sort
                                    by
                                    price</a>
                                <a className="dropdown-item" href="#" onClick={() => sortQueue('rating')}>Sort by
                                    rating</a>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <ul className="p-0 m-0">
                            {helpers.map(helper => {
                                return <div data-bs-target="#helperQueueModal" data-bs-toggle="modal"
                                            className="alert alert-light border d-flex row" key={helper.helper_queue_id}
                                            onClick={() => setSelectedHelper(helper)}>
                                    <div className="row d-flex">
                                        <ul className="list-unstyled users-list m-0 avatar-group d-flex align-items-center">
                                            <li className="avatar avatar-xs pull-up">
                                                <img src={baseUrl + helper.display_pic} alt="Avatar"
                                                     className="rounded-circle"/>
                                            </li>
                                            <li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</li>
                                            <li>
                                                <strong>{helper.helper_name}</strong>
                                            </li>

                                        </ul>
                                        <br/>
                                        <br/>
                                        <Stars count={helper.helper_stars.stars__avg} displayOnly={true}/>
                                    </div>
                                    <strong className="d-block mb-1"><i
                                        className="bx bx-rupee"></i>{helper.proposed_amount}</strong>
                                    {helper.proposed_at ? helper.proposed_at.replace('T', ' ') : "(not specified)"}
                                </div>
                            })}
                        </ul>
                    </div>
                </div>
            }
        </div>
        <div className="row d-flex justify-content-center align-items-center">
            <a href='#' style={{'width': '35%'}} type="button" className="btn btn-sm btn-outline-primary"
               data-bs-toggle="modal" data-bs-target="#objModal">Help summary</a>
            <a href='#' style={{'width': '35%'}} className="btn btn-sm btn-outline-danger"
               onClick={() => cancelHelp()}>Cancel?</a>

        </div>

        <div className="queue-loader">
            <span></span>
            <span></span>
            <span></span>
        </div>
        {props.help && <div className="modal fade" id="objModal" data-bs-backdrop="/static" tabIndex="-1">
            <div className="modal-dialog">
                <form className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="backDropModalTitle">Help summary</h5>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="modal-body">
                        <div className="card-body">
                            <small className="text-light fw-semibold">{obj.scheduled_at.replace('T', ' ')}</small>
                            <dl className="row mt-2">
                                <dt className="col-sm-3">Service</dt>
                                <dd className="col-sm-9">{obj.service}</dd>
                                <dt className="col-sm-3">Unit</dt>
                                <dd className="col-sm-9">{obj.unit}</dd>

                                <dt className="col-sm-3">Description</dt>
                                <dd className="col-sm-9">
                                    <p>{obj.description}</p>
                                </dd>


                                <dt className="col-sm-3">Address</dt>
                                <dd className="col-sm-9">
                                    <dl className="row">
                                        <dd className="col-sm-8">
                                            <div className="alert alert-secondary border">
                                                {obj.address.get_name},<br/>
                                                {obj.address.locality}, <br/>
                                                {obj.address.city}, <br/>
                                                {obj.address.postal_code}, <br/>
                                                {obj.address.state}, <br/>
                                                {obj.address.country}
                                            </div>
                                        </dd>
                                    </dl>
                                </dd>
                                <dt className="col-sm-3">Payment</dt>
                                <dd className="col-sm-9">
                                    <dl className="row">
                                        <dd className="col-sm-10">
                                            <div className="table-bordered table table-responsive">
                                                <table className="table">
                                                    <tbody>
                                                    <tr>
                                                        <th>Budget amount</th>
                                                        <th>
                                                            <i className="bx bx-rupee">{obj.budget_amount}</i>
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <th>Sub total</th>
                                                        <th>
                                                            <i className="bx bx-rupee">{obj.sub_total_amount}</i>
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <th>Tax (GST)</th>
                                                        <th>
                                                            <i className="bx bx-rupee">{obj.tax_amount}</i>
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <th><strong>Total</strong></th>
                                                        <th>
                                                            <i className="bx bx-rupee">{obj.total_amount}</i>
                                                        </th>
                                                    </tr>
                                                    </tbody>
                                                </table>

                                            </div>
                                        </dd>
                                    </dl>
                                </dd>
                            </dl>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" style={{'width': '100%'}} className="btn btn-outline-secondary"
                                data-bs-dismiss="modal">
                            Close
                        </button>
                    </div>
                </form>
            </div>
        </div>}
        <div className="modal fade" id="helperQueueModal" data-bs-backdrop="/static" tabIndex="-1">
            <div className="modal-dialog">
                <form className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="helperQueueModalTitle"></h5>
                        <button
                            id="close-helper-modal"
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="modal-body">
                        {selectedHelper &&
                            <Helper helper={selectedHelper} assignHelper={assignHelper} baseUrl={baseUrl}/>}
                    </div>
                </form>
            </div>
        </div>
    </React.Fragment>
}