import React from "react";
import HelpImages from "../utils/HelpImages";
import MapFrame from "../utils/MapFrame";
import Spinner from "../utils/Spinner";
import Statuses from "../utils/Statuses";
import UpdateStatus from "../utils/UpdateStatus";

export default function HelperTrack(props) {
    let obj = props.help;
    const statusClassDict = {
        1: 'alert-success',
        2: 'alert-primary',
        3: 'alert-info',
        4: 'alert-danger',

    }
    let baseUrl = props.url;
    const MINUTE_MS = 15000;

    function quickAction(action) {
        let otp = null;
        if (action === 'end') {
            otp = document.getElementById('otp').value;
            if (!otp) {
                props.setNotification('Please enter OTP to end help!')
            }
        }

        fetch(props.url + 'task/status/', {
            method: 'POST',
            headers: props.headers,
            body: JSON.stringify({
                'help_id': obj.id,
                'status': action === 'end' ? 'completed' : 'in_progress',
                'otp': otp
            })
        }).then(res => res.json()).then(res => {
            if (res.data) {
                props.setNotification(res.message);
                props.fetchHelp(obj.id)
            } else {
                props.setNotification(res.message || 'Something went wrong!');
            }
        });
    }
    React.useEffect(() => {
        props.fetchHelp(obj.id);
        const interval = setInterval(() => {
            props.fetchHelp(obj.id);
        }, MINUTE_MS);
        props.setPageTitle('Track');
        return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }, []);


    return <React.Fragment>
        {!obj ?
            <Spinner />
            :
            <section>
                <br />
                {props.help.status_val != 3 && <div className={"alert border " + statusClassDict[obj.status_val]}>
                    {obj.status_val === 2 && <div className="progress">
                        <div
                            className="progress-bar progress-bar-striped progress-bar-animated bg-success"
                            role="progressbar"
                            style={{ "width": "55%" }}
                            aria-valuenow="55"
                            aria-valuemin="0"
                            aria-valuemax="100"
                        ></div>
                    </div>}
                    {obj.status}
                </div>}
                <div className="align-items-center justify-content-center">
                    {props.help.status_val == 1 ? <button type="button"
                        className="btn btn-primary" onClick={() => quickAction('start')}>
                        Start
                    </button> : props.help.status_val == 2 ? <div className="align-items-center justify-content-center" align="center">
                        <button type="button" data-bs-toggle="modal"
                            data-bs-target="#updateStatusModal"
                            className="btn btn-primary">
                            Update status
                        </button>
                        <br />
                        <br />
                        <div className="d-flex align-items-center justify-content-center">

                            <input type="number" id="otp" placeholder="OTP from customer to end help." className="form-control" />
                            <button type="button" className="btn btn-success" onClick={() => quickAction('end')}>
                                End
                            </button></div></div>
                        : ''}

                </div>
                <br />
                <div className="row mb-5">

                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title">Help summary</h5>

                        </div>
                        <div className="card-body">
                            <div className="col-md-6 col-lg-4">
                                <h6 className="mt-2 text-muted">Location</h6>
                                <MapFrame latitude={obj.latitude} longitude={obj.longitude} />
                            </div>
                            <small className="text-light fw-semibold">{obj.scheduled_at.replace('T', ' ')}</small>
                            <dl className="row mt-2">
                                <dt className="col-sm-3">Service</dt>
                                <dd className="col-sm-9">{obj.service}</dd>
                                <dt className="col-sm-3">Unit</dt>
                                <dd className="col-sm-9">{obj.unit}</dd>

                                <dt className="col-sm-3">Description</dt>
                                <dd className="col-sm-9">
                                    <p>{obj.description}</p>
                                </dd>
                                <HelpImages baseUrl={baseUrl} images={props.images} />

                                <dt className="col-sm-3">Address</dt>
                                <dd className="col-sm-9">
                                    <dl className="row">
                                        <dd className="col-sm-8">
                                            <div className="alert alert-secondary border">
                                                {obj.address.get_name},<br />
                                                {obj.address.locality}, <br />
                                                {obj.address.city}, <br />
                                                {obj.address.postal_code}, <br />
                                                {obj.address.state}, <br />
                                                {obj.address.country}
                                            </div>
                                        </dd>
                                    </dl>
                                </dd>
                                <dt className="col-sm-3">Payment</dt>
                                <dd className="col-sm-9">
                                    <dl className="row">
                                        <dd className="col-sm-10">
                                            <div className="table-bordered table table-responsive">
                                                <table className="table">
                                                    <tbody>
                                                        <tr>
                                                            <th>Sub total</th>
                                                            <th>
                                                                <i className="bx bx-rupee">{obj.sub_total_amount}</i>
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <th>Tax (GST)</th>
                                                            <th>
                                                                <i className="bx bx-rupee">{obj.tax_amount}</i>
                                                            </th>
                                                        </tr>
                                                        <tr><th><strong>Total</strong></th>
                                                            <th>
                                                                <i className="bx bx-rupee">{obj.total_amount}</i>
                                                            </th>
                                                        </tr>
                                                    </tbody>
                                                </table>

                                            </div>
                                        </dd>
                                    </dl>
                                </dd>

                            </dl>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                        <Statuses baseUrl={baseUrl} statuses={props.statuses} />
                    </div>
                </div>
                <br />
                <br />
                <br />
                <br />
                <div style={{'marginRight':'2rem'}}> {obj.status_val < 3 &&
                    <a href={"tel:" + obj.customer_contact_no} className="btn btn-success btn-buy-now">
                        <i className="bx bx-phone"></i>
                        Call customer
                    </a>
                }&nbsp;&nbsp;
                    <a href="#" className="btn btn-success btn-buy-now" onClick={() => props.helpClicked(null)}>
                        <i className="bx bx-arrow-back"></i>
                        Back
                    </a> 
                    </div>
                <div className="modal fade" id="updateStatusModal" data-bs-backdrop="/static" tabIndex="-1">
                    <div className="modal-dialog">
                        <UpdateStatus url={props.url} customAlert={props.customAlert} cookies={props.cookies} helpId={obj.id} />
                    </div>
                </div>

            </section>
        }
    </React.Fragment>
}