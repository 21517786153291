import React from "react";

export default function UploadImage(props) {
    return <div key={props.idKey}>
        <div className="d-flex" id={props.idKey}>
        <span className="btn btn-danger btn-sm" onClick={() => {
            document.getElementById(props.idKey).remove();
        }}><i className="bx bx-x-circle"></i></span>
            <input type="file" name="images" className="form-control upload-image" placeholder="Image"
                   onChange={(event) => props.handleInputChange(event)}/>
        </div>
    </div>
}