import React from "react";

export default function HelpImages(props) {
    return <React.Fragment>
        <br />
        <dt className="col-sm-3">Images</dt>
        <dd className="col-sm-9">
            <dl className="row">
                {props.images && props.images.map(img => {
                    return <div className="col-sm-3" key={img} style={{ 'width': '33%' }}>
                        <a target={"blank"} href={props.baseUrl + img} className="card rounded">
                            <img className="card-img-top" src={props.baseUrl + img} alt="Help screenshot" />
                        </a>
                    </div>
                })}
            </dl>
        </dd>
        <br />
    </React.Fragment>
}