import React, {Component} from "react";
import axios from "axios";
import Spinner from "../utils/Spinner";

class Wallet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            passBookImg: null,
            panImg: null,
            bankInfo: null,
            bankForm: false,
            wallet: null,
        };
    }

    baseUrl = this.props.url.substring(0, this.props.url.length - 5);
    panImgChange = (event) => {
        event.preventDefault();
        this.setState({
            panImg: event.target.files[0]
        })
    };
    passBookImgChange = (event) => {
        event.preventDefault();
        this.setState({
            passBookImg: event.target.files[0]
        })
    };
    submitForm = (event) => {
        event.preventDefault();

        let reqBody = new FormData();
        reqBody.append('upi_id', document.getElementById('upi-id').value);
        reqBody.append('acc_number', document.getElementById('acc-no').value);
        reqBody.append('acc_name', document.getElementById('acc-name').value);
        reqBody.append('acc_ifsc', document.getElementById('acc-ifsc').value);
        reqBody.append('pan_no', document.getElementById('pan-no').value);
        if (this.state.passBookImg) {
            reqBody.append('acc_passbook_img', this.state.passBookImg, this.state.passBookImg.name);
        }
        if (this.state.panImg) {
            reqBody.append('pan_img', this.state.panImg, this.state.panImg.name);
        }

        axios.post(this.props.url + 'user/bank-info/',
            reqBody, {
                headers: {
                    'content-type': 'multipart/form-data',
                    Authorization: 'Token ' + this.props.cookies.get('user')['token']
                }
            }).then(res => {
            if (res.data.error) {
                this.props.setNotification(res.data.message || 'Something went wrong!');
            } else {
                this.props.setNotification(res.data.message);
                setTimeout(function () {
                    window.location.reload();
                }, 2000);
            }
        })
    }

    componentDidMount() {
        this.props.setPageTitle('My Wallet');
        fetch(this.props.url + 'user/bank-info/', {
            method: 'GET',
            headers: this.props.headers
        }).then(res => res.json()).then(res => {
            if (res.data) {
                this.setState({
                    bankInfo: res.data,
                });

            } else {
                this.props.setNotification(res.message);
                this.setState({
                    bankForm: true
                });
            }
        })

        fetch(this.props.url + 'user/wallet/', {
            method: 'GET',
            headers: this.props.headers,
        }).then(res => res.json()).then(res => {
            if (res.data) {
                this.setState({
                    wallet: res.data
                });
            } else {
                this.props.setNotification(res.message || 'Something went wrong!');
            }
        })
    }

    render() {
        return <React.Fragment>
            <div className="col-xl">
                {this.state.bankForm ? <div className="card mb-4">
                    <div className="card-header d-flex justify-content-between align-items-center">
                        <h5 className="mb-0">Update your Bank</h5>
                    </div>
                    <div className="card-body">
                        <form onSubmit={(event) => this.submitForm(event)}>
                            <div className="mb-3">
                                <label className="form-label" htmlFor="basic-icon-default-fullname">Bank account
                                    number</label>
                                <div className="input-group input-group-merge">
                                    <span id="basic-icon-default-fullname2" className="input-group-text">
                                        <i className="bx bxs-bank"></i></span>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="acc-no"
                                        placeholder="00000000000"
                                        required
                                    />
                                </div>
                            </div>
                            <div className="mb-3">
                                <label className="form-label" htmlFor="basic-icon-default-company">Bank account
                                    IFSC</label>
                                <div className="input-group input-group-merge">
                                    <span id="basic-icon-default-company2" className="input-group-text"
                                    ><i className="bx bxs-bank"></i
                                    ></span>
                                    <input
                                        type="text"
                                        id="acc-ifsc"
                                        className="form-control"
                                        placeholder="XXXX0000000"
                                        required
                                    />
                                </div>
                            </div>

                            <div className="mb-3">
                                <label className="form-label" htmlFor="basic-icon-default-company">Bank account holder's
                                    name</label>
                                <div className="input-group input-group-merge">
                                    <span id="basic-icon-default-company2" className="input-group-text"
                                    ><i className="bx bx-user"></i
                                    ></span>
                                    <input
                                        type="text"
                                        id="acc-name"
                                        className="form-control"
                                        placeholder="Jane Doe"
                                        required
                                    />
                                </div>
                            </div>


                            <div className="mb-3">
                                <label className="form-label" htmlFor="basic-icon-default-company">Pan card
                                    number</label>
                                <div className="input-group input-group-merge">
                                    <span id="basic-icon-default-company2" className="input-group-text"
                                    ><i className="bx bx-id-card"></i
                                    ></span>
                                    <input
                                        type="text"
                                        placeholder="XXXXXXXXXX"
                                        className="form-control"
                                        id="pan-no"
                                    />
                                </div>
                            </div>

                            <div className="mb-3">
                                <label className="form-label" htmlFor="basic-icon-default-company">UPI Id</label>
                                <div className="input-group input-group-merge">
                                    <span id="basic-icon-default-company2" className="input-group-text"
                                    ><i className="bx bx-wallet"></i
                                    ></span>
                                    <input
                                        type="text"
                                        placeholder="0000000000@XXXXX"
                                        className="form-control"
                                        id="upi-id"
                                    />
                                </div>
                            </div>


                            <div className="mb-3">
                                <label className="form-label" htmlFor="basic-icon-default-message">Pan card </label>
                                <br/>
                                <small>upload a scanned image here</small>
                                <div className="input-group input-group-merge">
                                    <span id="basic-icon-default-message2" className="input-group-text"><i
                                        className="bx bx-user-pin"></i></span>
                                    <input type="file" name="images" className="form-control upload-image"
                                           placeholder="Image"
                                           id="pan-img"
                                           onChange={(event) => this.panImgChange(event)}/>

                                </div>
                            </div>

                            <div className="mb-3">
                                <label className="form-label" htmlFor="basic-icon-default-message">Bank passbook</label>
                                <br/>
                                <small>upload a scanned image here</small>
                                <div className="input-group input-group-merge">
                                    <span id="basic-icon-default-message2" className="input-group-text"><i
                                        className="bx bx-user-pin"></i></span>
                                    <input type="file" name="images" className="form-control upload-image"
                                           placeholder="Image"
                                           id="passbook-img"
                                           onChange={(event) => this.passBookImgChange(event)}/>

                                </div>
                            </div>


                            <div className="mb-3">
                                <input type="checkbox" id="terms" required/>
                                <label className="form-check-label" htmlFor="terms">
                                    By clicking this checkbox, I agree to the <a href="#">terms & conditions.</a>
                                </label>
                            </div>
                            <div className="row d-flex align-items-center justify-content-center">
                                <button type="submit" className="btn btn-sm btn-primary">Update Bank</button>
                            </div>
                        </form>
                    </div>
                </div> : (!this.state.wallet || !this.state.bankInfo) ? <Spinner/> : <div className="row">
                    <div className="col-md mb-4 mb-md-0">
                        <div className="accordion mt-3" id="accordionExample">
                            <div className="card accordion-item active">
                                <h2 className="accordion-header" id="headingOne">
                                    <button
                                        type="button"
                                        className="accordion-button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#accordionOne"
                                        aria-expanded="true"
                                        aria-controls="accordionOne"
                                    >
                                        Wallet information
                                    </button>
                                </h2>

                                <div
                                    id="accordionOne"
                                    className="accordion-collapse collapse show"
                                    data-bs-parent="#accordionExample"
                                >

                                    <div className="accordion-body">
                                        <div className="alert alert-light border">Balance : <i
                                            className="bx bx-rupee"></i> {this.state.wallet.balance}</div>
                                        <div className="alert alert-light border">Rejection count
                                            : {this.state.wallet.rejection_count}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="card accordion-item">
                                <h2 className="accordion-header" id="headingThree">
                                    <button
                                        type="button"
                                        className="accordion-button collapsed"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#accordionThree"
                                        aria-expanded="false"
                                        aria-controls="accordionThree"
                                    >
                                        Payment history
                                    </button>
                                </h2>
                                <div
                                    id="accordionThree"
                                    className="accordion-collapse collapse"
                                    aria-labelledby="headingThree"
                                    data-bs-parent="#accordionExample"
                                >
                                    <div className="accordion-body">

                                        <div className="table-responsive text-nowrap">
                                            <table className="table table-sm">
                                                <thead>
                                                <tr>
                                                    <th>Help</th>
                                                    <th>My Earning</th>
                                                    <th>Status</th>
                                                </tr>

                                                </thead>
                                                <tbody className="table-border-bottom-0">
                                                {this.state.wallet.payments.map(pmt => {
                                                    return <tr>
                                                        <td>
                                                          {pmt.help}
                                                        </td>
                                                        <td>{pmt.my_cut}</td>
                                                        {pmt.status ?
                                                            <td><span
                                                                className="badge bg-label-success me-1">Disbursed</span>
                                                            </td> :
                                                            <td><span
                                                                className="badge bg-label-warning me-1">Pending</span>
                                                            </td>}
                                                    </tr>
                                                })}
                                                <tr>
                                                    <td>
                                                        <strong>Total earnings</strong>
                                                    </td>
                                                    <td></td>
                                                    <td>{this.state.wallet.total_cut}</td>

                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="card accordion-item">
                                <h2 className="accordion-header" id="headingTwo">
                                    <button
                                        type="button"
                                        className="accordion-button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#accordionTwo"
                                        aria-expanded="false"
                                        aria-controls="accordionTwo"
                                    >
                                        Bank account information
                                    </button>
                                </h2>

                                <div
                                    id="accordionTwo"
                                    className="accordion-collapse collapse"
                                    data-bs-parent="#accordionExample"
                                >

                                    <div className="accordion-body">
                                        <div className="alert alert-light border">Bank account number
                                            : {this.state.bankInfo.acc_number}</div>
                                        <div className="alert alert-light border">Bank account IFSC
                                            : {this.state.bankInfo.acc_ifsc}</div>
                                        <div className="alert alert-light border">Account holder
                                            : {this.state.bankInfo.acc_name}</div>
                                        <img src={this.baseUrl + this.state.bankInfo.acc_passbook_img}
                                             className="img-fluid"
                                             alt="acc-img"/>

                                    </div>
                                </div>
                            </div>
                            <div className="card accordion-item">
                                <h2 className="accordion-header" id="headingFour">
                                    <button
                                        type="button"
                                        className="accordion-button collapsed"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#accordionFour"
                                        aria-expanded="false"
                                        aria-controls="accordionFour"
                                    >
                                        Other information
                                    </button>
                                </h2>
                                <div
                                    id="accordionFour"
                                    className="accordion-collapse collapse"
                                    aria-labelledby="headingFour"
                                    data-bs-parent="#accordionExample"
                                >
                                    <div className="accordion-body">
                                        <div className="alert alert-light border">UPI Id
                                            : {this.state.bankInfo.upi_id}</div>
                                        <div className="alert alert-light border">Pan card number
                                            : {this.state.bankInfo.pan_no}</div>
                                        <img src={this.baseUrl + this.state.bankInfo.pan_img} className="img-fluid"
                                             alt="acc-img"/>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>}
            </div>
        </React.Fragment>
    }
}

export default Wallet;