export default function MapFrame(props) {
    const mapsApiKey = 'AIzaSyAEFu4WXq3lD7kAfaTV_A92ZJ4r5pg84U8';
    return <iframe
        className="card shadow-lg"
        style={{ "width": '100%' }}
        loading="lazy"
        allowFullScreen
        referrerPolicy="no-referrer-when-downgrade"
        src={"https://maps.google.com/maps/embed/v1/place?key=" + mapsApiKey + "&q=" + props.latitude + "," + props.longitude + ""}>
    </iframe>
}